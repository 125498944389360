import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledWrapper = styled.div`
  padding: 66px 22px 22px 22px;
  margin-left: 216px;
  height: calc(100vh - 46px);
  width: calc(100vw - 216px);
`;

export const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
  margin-bottom: 12px;
`;

export const StyledTableItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-bottom: 2px solid ${(props) => props.theme.darkGray};
`;

export const StyledTableItem = styled.div<{ maxWidth?: string }>`
  display: inline-block;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 10%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
`;

export const StyledCheckbox = styled.input`
  width: 26px;
  height: 26px;
  margin: 0;
`;

export const StyledButtonLink = styled(Link)`
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledModalText = styled.div`
  font-size: 1.125rem;
`;
