import customAxios from 'libs/CustomAxios';
import {
  IGetFAQListReponse,
  IFAQInfo,
  IFAQOrderUpdateRequest,
} from 'types/FAQ';
import { IResponse } from 'types/Response.type';

export const handleGetFAQList = async (
  category: string,
): Promise<IGetFAQListReponse> => {
  let url: string = `/faq/find?category=${category}`;
  const { data } = await customAxios.get(url);
  return data;
};

export const handleInsertFAQ = async (req: IFAQInfo): Promise<IResponse> => {
  const url: string = `/faq/insert`;
  const { data } = await customAxios.post(url, req);
  return data;
};

export const handleDeleteFAQ = async (faqId: number): Promise<IResponse> => {
  const url: string = `/faq/delete?faqId=${faqId}`;
  const { data } = await customAxios.delete(url);
  return data;
};

export const handleEditFAQ = async (req: IFAQInfo): Promise<IResponse> => {
  const url: string = `/faq/update`;
  const { data } = await customAxios.post(url, req);
  return data;
};

export const handleEditFAQOrder = async (
  req: IFAQOrderUpdateRequest[],
): Promise<IResponse> => {
  const url: string = `/faq/ordering`;
  const { data } = await customAxios.post(url, req);
  return data;
};
