import Toast from 'libs/Toast';
import AWS from 'aws-sdk';

const useUpload = () => {
  const region = 'ap-northeast-2';
  const bucket = 'wine-project-prod/wine';

  AWS.config.update({
    region: region,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const handleFileInput = async (file: any, imgUrl: any) => {
    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucket,
        Key: imgUrl,
        Body: file,
      },
    });
    const promise = upload.promise();
    promise.then(
      function () {
        Toast.successToast('사진 업로드 완료');
      },
      function (err) {
        Toast.errorToast('사진 업로드 실패');
      },
    );
  };

  return {
    handleFileInput,
  };
};

export default useUpload;
