import { memo, useEffect, useRef } from 'react';
import TableRow from 'components/common/TableRow';
import TableHead from 'components/common/TableHead';
import {
  StyledWrapper,
  StyledTitle,
  StyledTableItemWrap,
  StyledTableItem,
  StyledButtonWrapper,
  StyledModalText,
  StyledButtonWrapperForCan,
  StyledInitButton,
  StyledTableItemWrapForInput,
  StyledSearchText,
  StyledTableItemWrapForSearchInput,
  StyledCheckbox
} from './styles';
import Button from 'components/common/Button';
import useVoucher from 'hooks/useVoucher';
import SelectBox from 'components/common/SelectBox';
import {
  IGetVoucherListResponse
} from 'types/Voucher.type';
import Pagination from 'components/Pagination';
import Portal from 'components/common/Modal/Portal';
import Modal from 'components/common/Modal';
import { MonthList } from 'constants/constants';

const Voucher = () => {
  const {
    voucherList,
    allCount,
    getVoucherList,
    pageIndex,
    setPageIndex,
    nameValue,
    idValue,
    monthValue,
    textValue,
    handlerUpdateDescription,
    handlerCancel,
    userInfo,
    openUploadModal,
    setOpenUploadModal,
    voucherIdValidation,
    isUsed, 
    setIsUsed,
    isNotUsed, 
    setIsNotUsed,
    isExpired, 
    setIsExpired,
    onChangeSelectBoxMonthItem,
    checkedMonth
  } = useVoucher();

  useEffect(() => {
    getVoucherList();
  }, [pageIndex, isUsed, isNotUsed, isExpired]);

  const ModalContent = () => {
    return (
      <Portal>
        <Modal
          width="460px"
          height="150px"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {
            handlerCancel(voucherIdValidation.current);
          }}>
          <StyledModalText>
            {
              '취소 시 교환권이 삭제됩니다.\n정말 취소하시겠습니까?\n\n결제취소: 토스관리자페이지에서 진행'
            }
          </StyledModalText>
        </Modal>
      </Portal>
    );
  };

  return (
    <StyledWrapper>
      <StyledTitle>교환권 관리</StyledTitle>

      <StyledButtonWrapper>
        <StyledSearchText whiteSpace='nowrap'>이름</StyledSearchText>
        <StyledTableItemWrapForSearchInput
          onChange={(e) => {
            nameValue.current = e.target.value;
          }}
          placeholder={'이름을 입력해주세요'}
          maxLength={5}
        />
        <StyledSearchText whiteSpace='nowrap'>ID</StyledSearchText>
        <StyledTableItemWrapForSearchInput
          onChange={(e) => {
            idValue.current = e.target.value;
          }}
          placeholder={'ID를 입력해주세요'}
          maxLength={20}
        />
        <StyledSearchText whiteSpace='nowrap'>월</StyledSearchText>
        <SelectBox
          items={MonthList}
          value={checkedMonth.value}
          handleChange={onChangeSelectBoxMonthItem}
          width="20%"
        />
        {/* <StyledTableItemWrapForSearchInput
          onChange={(e) => {
            monthValue.current = Number(e.target.value);
          }}
          placeholder={'구독 월을 입력해주세요'}
          maxLength={20}
          type="number"
        /> */}
        {/* <StyledSearchText>전체</StyledSearchText>
        <StyledCheckbox
          type="checkbox" 
          defaultChecked
          onChange={(e) =>{
            if (e.target.checked === false) {
              setIsUsed(!e.target.checked);
              setIsNotUsed(!e.target.checked);
              setIsExpired(!e.target.checked);
            }
            
          }}
        /> */}
        
        <StyledSearchText whiteSpace='nowrap'>사용</StyledSearchText>
        <StyledCheckbox
          type="checkbox" 
          defaultChecked
          marginRight='10px'
          onChange={(e) =>{
            setIsUsed(e.target.checked);
            if (e.target.checked === true) {
              setIsNotUsed(false);
              setIsExpired(false);
            }
          }}
          checked={isUsed}
        />
        
        <StyledSearchText whiteSpace='nowrap'>미사용</StyledSearchText>
        <StyledCheckbox
          type="checkbox" 
          //defaultChecked
          marginRight='10px'
          onChange={(e) =>{
            setIsNotUsed(e.target.checked);
            if (e.target.checked === true) {
              setIsUsed(false);
              setIsExpired(false);
            }
          }}
          checked={isNotUsed}
        />
        <StyledSearchText whiteSpace='nowrap'>만료</StyledSearchText>
        <StyledCheckbox
          type="checkbox" 
          //defaultChecked
          marginRight='10px'
          onChange={(e) =>{
            setIsExpired(e.target.checked);
            if (e.target.checked === true) {
              setIsNotUsed(false);
              setIsUsed(false);
            }
          }}
          checked={isExpired}
        />
        <div style={{ width: '40%' }} />
        <Button
          content="검색"
          height="40%"
          width="7%"
          handleClick={getVoucherList}
          //padding="px"
        />
        <div style={{ width: '5%' }} />
        <Button
          content="저장"
          height="40%"
          width="7%"
          handleClick={handlerUpdateDescription}
        />
      </StyledButtonWrapper>

      <StyledTableItemWrap>
        <table width="calc(100vw - 260px)">
          {userInfo.userId === 4 ? (
            <TableHead
              titles={[
                { text: 'NO', value: 5 },
                { text: '이름', value: 5 },
                { text: '메일주소', value: 10 },
                { text: '월', value: 5 },
                { text: '구독권종류', value: 5 },
                { text: '교환권상태', value: 10 },
                { text: '비고', value: 20 },
                { text: '취소', value: 5 },
              ]}
            />
          ) : (
            <TableHead
              titles={[
                { text: 'NO', value: 5 },
                { text: '이름', value: 5 },
                { text: '메일주소', value: 10 },
                { text: '월', value: 5 },
                { text: '구독권종류', value: 5 },
                { text: '교환권상태', value: 10 },
                { text: '비고', value: 20 },
              ]}
            />
          )}
          {userInfo.userId === 4 ? (
            <tbody>
              {voucherList.map(
                (item: IGetVoucherListResponse, index: number) => (
                  <TableRow key={item.voucherId}>
                    <StyledTableItem>{index + 1}</StyledTableItem>
                    <StyledTableItem>{item.userName}</StyledTableItem>
                    <StyledTableItem>{item.userMail}</StyledTableItem>
                    <StyledTableItem>{item.month > 12 ? item.month - 12 : item.month}</StyledTableItem>
                    <StyledTableItem>{item.subscriptionName}</StyledTableItem>
                    <StyledTableItem>
                      {item.status === 'USED'
                        ? '사용'
                        : item.status === 'NOT_USED'
                        ? '미사용'
                        : '기한만료'}
                    </StyledTableItem>
                    <StyledTableItemWrapForInput
                      onChange={(e) => {
                        textValue.current.find(
                          (e) => e.voucherId === item.voucherId,
                        )!.description = e.target.value;
                      }}
                      defaultValue={item.description}
                      maxLength={20}
                    />
                    <StyledButtonWrapperForCan>
                      <StyledInitButton
                        onClick={() => {
                          voucherIdValidation.current = item.voucherId;
                          setOpenUploadModal(true);
                        }}>
                        취소
                      </StyledInitButton>
                    </StyledButtonWrapperForCan>
                  </TableRow>
                ),
              )}
            </tbody>
          ) : (
            <tbody>
              {voucherList.map(
                (item: IGetVoucherListResponse, index: number) => (
                  <TableRow key={item.voucherId}>
                    <StyledTableItem>{index + 1}</StyledTableItem>
                    <StyledTableItem>{item.userName}</StyledTableItem>
                    <StyledTableItem>{item.userMail}</StyledTableItem>
                    <StyledTableItem>{item.month > 12 ? item.month - 12 : item.month}</StyledTableItem>
                    <StyledTableItem>{item.subscriptionName}</StyledTableItem>
                    <StyledTableItem>
                      {item.status === 'USED'
                        ? '사용'
                        : item.status === 'NOT_USED'
                        ? '미사용'
                        : '기한만료'}
                    </StyledTableItem>
                    <StyledTableItemWrapForInput
                      onChange={(e) => {
                        textValue.current.find(
                          (e) => e.voucherId === item.voucherId,
                        )!.description = e.target.value;
                      }}
                      defaultValue={item.description}
                      maxLength={20}
                    />
                  </TableRow>
                ),
              )}
            </tbody>
          )}
        </table>
      </StyledTableItemWrap>

      <Pagination
        allCount={allCount}
        selectedNumber={pageIndex}
        setSelectedNumber={setPageIndex}
        onClick={setPageIndex}
      />
      {openUploadModal && ModalContent()}
    </StyledWrapper>
  );
};

export default memo(Voucher);
