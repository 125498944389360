import { memo, useEffect } from 'react';
import {
  StyledTitle,
  StyledWrapper,
  StyledItemWrapper,
  StyledItemText,
  StyledItemInputBox,
  StyledButtonWrapper,
  StyledSaveButton,
} from './styles';
import useYoutubeManagement from 'hooks/useYoutubeManagement';

const YoutubeManagement = () => {
  const { getYoutubeList, youtubeList, youtubeListValue, saveYoutubeList } =
    useYoutubeManagement();

  useEffect(() => {
    getYoutubeList();
  }, []);

  return (
    <StyledWrapper>
      <StyledTitle>영상 관리</StyledTitle>
      <StyledButtonWrapper>
        <StyledSaveButton onClick={saveYoutubeList}>저장</StyledSaveButton>
      </StyledButtonWrapper>
      <StyledItemWrapper>
        <StyledItemText>1번째 영상 주소</StyledItemText>
        <StyledItemInputBox
          defaultValue={
            youtubeList === undefined
              ? undefined
              : youtubeList[0].youtubeShortsUrl
          }
          onChange={(e) => {
            youtubeListValue.current![0].youtubeShortsUrl = e.target.value;
          }}
        />
      </StyledItemWrapper>

      <StyledItemWrapper>
        <StyledItemText>2번째 영상 주소</StyledItemText>
        <StyledItemInputBox
          defaultValue={
            youtubeList === undefined
              ? undefined
              : youtubeList[1].youtubeShortsUrl
          }
          onChange={(e) => {
            youtubeListValue.current![1].youtubeShortsUrl = e.target.value;
          }}
        />
      </StyledItemWrapper>

      <StyledItemWrapper>
        <StyledItemText>3번째 영상 주소</StyledItemText>
        <StyledItemInputBox
          defaultValue={
            youtubeList === undefined
              ? undefined
              : youtubeList[2].youtubeShortsUrl
          }
          onChange={(e) => {
            youtubeListValue.current![2].youtubeShortsUrl = e.target.value;
          }}
        />
      </StyledItemWrapper>
    </StyledWrapper>
  );
};

export default memo(YoutubeManagement);
