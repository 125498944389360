import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledWrapper = styled.div`
  padding: 66px 22px 22px 22px;
  margin-left: 216px;
  height: calc(100vh - 46px);
  width: calc(100vw - 216px);
`;

export const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
  margin-bottom: 12px;
`;

export const StyledTableItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  overflow-y: scroll;
  border-bottom: 2px solid ${(props) => props.theme.darkGray};
`;

export const StyledTableItem = styled.div<{ maxWidth?: string }>`
  display: inline-block;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
`;

export const StyledButtonWrapper = styled.div<{ maxWidth?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.black};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
`;

export const StyledInitButton = styled.button`
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  width: 80%;
  height: 100%;
  background-color: ${(props) => props.theme.mainColor};
  outline: 0;
  border: none;
  border-radius: 5px;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledButtonLink = styled(Link)`
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledModalText = styled.div`
  font-size: 1.125rem;
`;

export const StyledSearchButtonWrapper = styled.div`
  width: 100%;
  height: 10%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

export const StyledTableItemWrapForInput = styled.input`
  display: flex;
  flex-direction: column;
  width: 10%;
  height: 30%;
  overflow-y: scroll;
  border-bottom: 2px solid ${(props) => props.theme.darkGray};
  margin-right: 10%;
`;

export const StyledSearchText = styled.text`
  height: 50%;
  width: 5%;
  white-space: pre-wrap;
  font-size: 1.5rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
`;