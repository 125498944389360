import { memo, useEffect } from 'react';
import TableRow from 'components/common/TableRow';
import TableHead from 'components/common/TableHead';
import {
  StyledWrapper,
  StyledTitle,
  StyledTableItemWrap,
  StyledTableItem,
  StyledButtonWrapper,
  StyledCheckbox,
  StyledButtonLink,
  StyledModalText,
  StyledText,
  StyledSelectBoxWrapper,
  StyledImgButton,
  StyledButtonImg,
} from './styles';
import Button from 'components/common/Button';
import Portal from 'components/common/Modal/Portal';
import Modal from 'components/common/Modal';
import useFAQ from 'hooks/useFAQ';
import SelectBox from 'components/common/SelectBox';
import { FAQCategoryList } from 'constants/constants';

const FAQ = () => {
  const {
    faqList,
    checkedFAQInfo,
    setCheckedFAQInfo,
    onChangeSelectBoxCategoryItem,
    checkedCategory,
    getFAQList,
    deleteFAQ,
    editOrderFAQ,
    editOrderSave,
  } = useFAQ();

  return (
    <StyledWrapper>
      <StyledTitle>FAQ 관리</StyledTitle>
      <StyledButtonWrapper>
        <StyledButtonLink to="/faqEdit?type=new">
          <Button content="신규" height="40%" width="80%" />
        </StyledButtonLink>
        <StyledButtonLink to="/faqEdit?type=edit">
          <Button content="수정" height="40%" width="80%" />
        </StyledButtonLink>
        <StyledButtonLink to="/faq">
          <Button
            content="삭제"
            height="40%"
            width="80%"
            handleClick={deleteFAQ}
          />
        </StyledButtonLink>
      </StyledButtonWrapper>

      <StyledSelectBoxWrapper>
        <StyledText>카테고리 :</StyledText>
        <div style={{ width: '1%' }} />
        <SelectBox
          items={FAQCategoryList}
          value={checkedCategory.value}
          handleChange={onChangeSelectBoxCategoryItem}
          width="20%"
        />
        <div style={{ width: '3%' }} />
        <Button
          content="조회"
          height="40%"
          width="5%"
          handleClick={getFAQList}
        />
        <div style={{ width: '30%' }} />
        <StyledImgButton
          onClick={() => {
            editOrderFAQ(1);
          }}>
          <StyledButtonImg
            src={process.env.PUBLIC_URL + '/icons/doubleUpper.png'}
          />
        </StyledImgButton>

        <div style={{ width: '1%' }} />
        <StyledImgButton
          onClick={() => {
            editOrderFAQ(2);
          }}>
          <StyledButtonImg src={process.env.PUBLIC_URL + '/icons/upper.png'} />
        </StyledImgButton>

        <div style={{ width: '1%' }} />
        <StyledImgButton
          onClick={() => {
            editOrderFAQ(3);
          }}>
          <StyledButtonImg src={process.env.PUBLIC_URL + '/icons/down.png'} />
        </StyledImgButton>

        <div style={{ width: '1%' }} />
        <StyledImgButton
          onClick={() => {
            editOrderFAQ(4);
          }}>
          <StyledButtonImg
            src={process.env.PUBLIC_URL + '/icons/doubleDown.png'}
          />
        </StyledImgButton>

        <div style={{ width: '5%' }} />
        <Button
          content="저장"
          height="40%"
          width="5%"
          handleClick={editOrderSave}
        />
      </StyledSelectBoxWrapper>

      <StyledTableItemWrap>
        <table width="calc(100vw - 260px)">
          <TableHead
            titles={[
              { text: '', value: 3 },
              { text: '게시순서', value: 10 },
              { text: '질문', value: 45 },
              { text: '답변', value: 45 },
            ]}
          />
          <tbody>
            {faqList.map((item: any) => (
              <TableRow>
                <StyledCheckbox
                  type="checkbox"
                  onChange={() => {
                    setCheckedFAQInfo(item);
                  }}
                  checked={checkedFAQInfo.faqId == item.faqId}
                />
                <StyledTableItem>{item.ordering}</StyledTableItem>
                <StyledTableItem>{item.question}</StyledTableItem>
                <StyledTableItem>{item.answer}</StyledTableItem>
              </TableRow>
            ))}
          </tbody>
        </table>
      </StyledTableItemWrap>
      {/* {openUploadModal && ModalContent()} */}
    </StyledWrapper>
  );
};

export default memo(FAQ);
