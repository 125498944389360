import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: 66px 22px 22px 22px;
  margin-left: 216px;
  height: calc(100vh - 46px);
  width: calc(100vw - 216px);
`;

export const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
  margin-bottom: 12px;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 10%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
`;

export const StyledRowWrapper = styled.div`
  width: 100%;
  height: 7%;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const StyledRowTitle = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const StyledRowInputBox = styled.input`
  width: 50%;
  height: 90%;
`;

export const StyledImgFileUpload = styled.input``;

export const StyledImgPreview = styled.img`
  width: 20%;
  aspect-ratio: 1/1;
  object-fit: contain;
`;

export const StyledText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
`;
