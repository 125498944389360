import styled from 'styled-components';

export const StyledSearchProductFooter = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px;
  gap: 32px;
`;

export const StlyedCurrentPage = styled.div<{selected?: boolean}>`
  font-size: ${(props) => props.selected ? '1.5rem' : '1.25rem'};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) => props.selected ? 'blue' : 'black'};
  text-decoration: ${(props) => props.selected ? 'underline' : undefined};;
  cursor: pointer;
`;
