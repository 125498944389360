import { useCallback, useState } from 'react';
import {
  ILogin,
  ITokens,
  ILoginResponse,
  ILoginResponseData,
} from 'types/Login.type';
import { handlePostLogin } from 'libs/apis/login.api';
import { getDateSubtract } from 'utils/utils';
import { IUserInfo } from 'types/User.type';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getUserInfoAtom } from 'store/user';

const useLogin = () => {
  const setUserList = useSetRecoilState<IUserInfo>(getUserInfoAtom);
  const [info, setInfo] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  });

  const handleLogin = useCallback(async (): Promise<void> => {
    const loginRequest: ILogin = {
      email: info.email,
      pw: info.password,
    };
    try {
      const data: ILoginResponse = await handlePostLogin(loginRequest);
      const userDataTemp = {
        userId: data.data.data.loginResult.first,
        userName: data.data.data.userName,
        email: '',
        recommendationId: 0,
        subscriptionIdList: '',
        isAdult: '',
        customerKey: '',
        cardStatus: '',
      };
      setUserList({
        userId: data.data.data.loginResult.first,
        email: '',
        userName: data.data.data.userName,
        recommendationId: 0,
        subscriptionIdList: '',
        subscriptionTypeList: '',
      });
      const currentDate = new Date();
      const currentDateString = currentDate.toISOString().substring(0, 10);
      localStorage.setItem(
        'token',
        data.data.data.loginResult.second.accessToken,
      );
      localStorage.setItem('userInfo', JSON.stringify(userDataTemp));
      localStorage.setItem('loginDate', currentDateString);
      window.location.href = '/main';
    } catch (e: any) {}
  }, [info, setUserList]);

  const handleKeyPress = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
      if (e.key === 'Enter') {
        await handleLogin();
      }
    },
    [handleLogin],
  );

  const checkLoginStatus = useCallback(async () => {
    // const token: string | null = localStorage.getItem('token');
    // const userInfo: string | null = localStorage.getItem('userInfo');
    // const loginDate: string | null = localStorage.getItem('loginDate');
    // const diff = getDateSubtract();
    // if (diff >= 3) {
    //   window.location.replace('/');
    //   return;
    // }
  }, []);

  return {
    info,
    setInfo,
    handleLogin,
    handleKeyPress,
    checkLoginStatus,
  };
};

export default useLogin;
