import { StyledInput } from './styles';
import { IInput } from 'types/Input.type';
import React, { CSSProperties, FC, memo } from 'react';

const Input: FC<IInput> = (props) => {
  const {
    type,
    value,
    name,
    handleChange,
    placeholder,
    backgroundColor,
    borderRadius,
    color,
    customStyle,
    fontSize,
    height,
    margin,
    padding,
    width,
    maxLength,
    isAutoFocus = false,
    isReadOnly = false,
    isSpaceBar = false,
    handleKeyPress,
  } = props;

  const style: CSSProperties = {
    ...customStyle,
    backgroundColor,
    borderRadius,
    color,
    fontSize,
    height,
    margin,
    padding,
    width,
  };

  return (
    <StyledInput
      type={type}
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      placeholder={placeholder}
      style={style}
      autoFocus={isAutoFocus}
      readOnly={isReadOnly}
      isReadOnly={isReadOnly}
      name={name}
      maxLength={maxLength}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (isSpaceBar && e.key === ' ' && value.length > 0) {
          // 스페이스바눌렀을때 해당 value값으로 웹페이지 이동하기 위함
          // sideEffect방지용으로 stopPropagation & preventDefault 사용
          e.stopPropagation();
          e.preventDefault();
          window.open(value);
        }
      }}
    />
  );
};

export default memo(Input);
