import customAxios from 'libs/CustomAxios';
import {
  IGetYoutubeListReponse,
  IYoutubeUrlUpdateRequest,
} from 'types/Youtube.type';
import { IResponse } from 'types/Response.type';

export const handleGetYoutubeList =
  async (): Promise<IGetYoutubeListReponse> => {
    const url: string = `/youtubeShorts`;
    const { data } = await customAxios.get(url);
    return data;
  };

export const handleUpdateYoutubeList = async (
  req: IYoutubeUrlUpdateRequest[],
): Promise<IResponse> => {
  const url: string = `/youtubeShorts/update`;
  const { data } = await customAxios.post(url, req);
  return data;
};
