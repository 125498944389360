import {
  CSSProperties,
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  StyledButtonWrapper,
  StyledCloseImage,
  StyledModal,
  StyledModalBackground,
  StyledModalContent,
} from './styles';
import Portal from './Portal';
import Button from '../Button';
import { ThemeConfig } from 'styles/ThemeConfig';

interface IModal {
  children: ReactNode;
  onClose: () => void;
  onYes?: () => void;
  onNo?: () => void;
  width?: string;
  height?: string;
  customStyle?: CSSProperties;
  isYesButton?: boolean;
  isNoButton?: boolean;
  yesComment?: string;
  noComment?: string;
  isClose?: boolean;
}

const Modal = ({
  children,
  onYes,
  onNo,
  onClose,
  customStyle,
  width = '700px',
  height = '400px',
  isYesButton = false,
  isNoButton = false,
  yesComment = '예',
  noComment = '아니요',
  isClose = false,
}: IModal) => {
  const modalRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const onClickOutSide = useCallback(
    (e: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    },
    [onClose],
  );
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSide);
    // 바깥영역클릭시 모달 종료
    return () => {
      document.removeEventListener('mousedown', onClickOutSide);
    };
  }, [onClickOutSide]);

  return (
    <Portal>
      <StyledModalBackground>
        <StyledModal
          width={width}
          height={height}
          style={customStyle}
          ref={modalRef}
        >
          <StyledModalContent>{children}</StyledModalContent>
          <StyledButtonWrapper>
            {isYesButton && (
              <Button
                content={noComment}
                handleClick={onNo}
                width="30%"
                fontSize="1.125rem"
                color={ThemeConfig.black}
                backgroundColor={ThemeConfig.white}
                customStyle={{
                  fontWeight: 'bold',
                  border: `2px solid ${ThemeConfig.darkGray}`,
                }}
              />
            )}
            {isNoButton && (
              <Button
                content={yesComment}
                handleClick={onYes}
                width="30%"
                fontSize="1.125rem"
                customStyle={{ fontWeight: 'bold' }}
              />
            )}
          </StyledButtonWrapper>
          {isClose && <StyledCloseImage onClick={onClose} />}
        </StyledModal>
      </StyledModalBackground>
    </Portal>
  );
};

export default memo(Modal);
