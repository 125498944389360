import {
  StyledWrapper,
  StyledTitle,
  StyledButtonWrapper,
  StyledRowWrapper,
  StyledRowTitle,
  StyledRowInputBox,
  StyledImgFileUpload,
  StyledImgPreview,
  StyledText,
} from './styles';
import Button from 'components/common/Button';
import useQueryString from 'hooks/useQueryString';
import { memo, useEffect } from 'react';
import useNotice from 'hooks/useNotice';
import SelectBox from 'components/common/SelectBox';

const Notice = () => {
  const {
    saveImgFile,
    imgRef,
    imgFile,
    insertImage,
    selectedItem,
    itemsList,
    onChangeSelectBoxItem,
    getIamge,
    alreadyData,
  } = useNotice();

  useEffect(() => {
    getIamge();
    
  }, []);

  const GetStyledRow = (title: string) => {
    return (
      <StyledRowWrapper>
        <StyledRowTitle>{title}</StyledRowTitle>
        <StyledImgFileUpload
          type="file"
          accept="image/*"
          onChange={saveImgFile}
          ref={imgRef}
        />
      </StyledRowWrapper>
    );
  };

  return (
    <StyledWrapper>
      <StyledTitle>팝업 이미지 등록</StyledTitle>
      {GetStyledRow('사진')}
      <StyledImgPreview src={imgFile ? imgFile : alreadyData.image} />
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width: '18%',
          }}>
          <StyledText>노출여부 :</StyledText>
          <SelectBox
            defaultValue={alreadyData.enable}
            items={itemsList}
            value={selectedItem.value}
            handleChange={onChangeSelectBoxItem}
            width="30%"
          />
        </div>
        <Button
          content={'저장'}
          height="3%"
          width="5%"
          handleClick={insertImage}
        />
      </div>
    </StyledWrapper>
  );
};

export default memo(Notice);
