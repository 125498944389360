import {
  useCallback,
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { handleGetWineList } from 'libs/apis/wine.api';
import {
  handleGetSubscriptionList,
  handleRegisterDeleteWineInSubscription,
} from 'libs/apis/subscription.api';
import { MonthList, SubscriptionList } from 'constants/constants';
import { IWineInfo } from 'types/Wine.type';
import Toast from 'libs/Toast';
import { IRegisterDeleteWineInSubscriptionRequest } from 'types/Subscription.type';

const useSubscription = () => {
  const [checkedMonth, setCheckedMonth] = useState<any>({
    name: '5월',
    value: 5,
  });
  const [checkedSubscription, setCheckedSubscription] = useState<any>({
    name: '구독권A',
    value: 1,
  });
  const [checkedNotIncludeWine, setCheckedNotIncludeWine] = useState<
    IWineInfo[]
  >([]);
  const [checkedIncludeWine, setCheckedIncludeWine] = useState<IWineInfo[]>([]);
  const firstWineData = useRef<any>([]);
  const RegisterDeleteWineInSubscriptionRequest =
    useRef<IRegisterDeleteWineInSubscriptionRequest>({
      subscriptionId: 0,
      wineIdsToRegister: [],
      wineIdsToDelete: [],
    });

  const checkInit = useCallback(() => {
    let checkedNotIncludeWineTemp: IWineInfo[] = [];
    let checkedIncludeWineTemp: IWineInfo[] = [];
    firstWineData.current[0].forEach((item: any) => {
      item.stats = 0;
      checkedNotIncludeWineTemp.push(item);
    });
    firstWineData.current[1].forEach((item: any) => {
      item.stats = 0;
      checkedIncludeWineTemp.push(item);
    });
    setCheckedNotIncludeWine(checkedNotIncludeWineTemp);
    setCheckedIncludeWine(checkedIncludeWineTemp);
  }, [
    checkedNotIncludeWine,
    setCheckedNotIncludeWine,
    checkedIncludeWine,
    setCheckedIncludeWine,
  ]);

  const checkWineList = useCallback(
    (
      item: IWineInfo,
      data: IWineInfo[],
      setHandler: Dispatch<SetStateAction<IWineInfo[]>>,
      currentStatus: number,
    ) => {
      let dataTemp: IWineInfo[] = [...data];
      currentStatus % 2 === 0
        ? dataTemp.find((e) => e.wineId === item.wineId)!.status++
        : dataTemp.find((e) => e.wineId === item.wineId)!.status--;
      setHandler(dataTemp);
    },
    [
      checkedNotIncludeWine,
      setCheckedNotIncludeWine,
      checkedIncludeWine,
      setCheckedIncludeWine,
    ],
  );

  const onChangeSelectBoxMonthItem = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
      setCheckedMonth({
        name: MonthList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.name,
        value: MonthList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.value,
      });
    },
    [checkedMonth, setCheckedMonth],
  );

  const onChangeSelectBoxSubscriptionItem = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
      setCheckedSubscription({
        name: SubscriptionList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.name,
        value: SubscriptionList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.value,
      });
    },
    [checkedSubscription, setCheckedSubscription],
  );

  const insertToSubscriptionWineList = useCallback(() => {
    let checkedIncludeWineTemp: IWineInfo[] = [...checkedIncludeWine];
    let checkedNotIncludeWineTemp: IWineInfo[] = [];
    checkedNotIncludeWine.forEach((item: IWineInfo) => {
      if (item.status % 2 === 1) {
        firstWineData.current[1].find(
          (e: IWineInfo) => e.wineId === item.wineId,
        ) === undefined
          ? (item.status = 4)
          : (item.status = 0);
        checkedIncludeWineTemp.push(item);
      } else {
        checkedNotIncludeWineTemp.push(item);
      }
    });
    setCheckedIncludeWine(checkedIncludeWineTemp);
    setCheckedNotIncludeWine(checkedNotIncludeWineTemp);
  }, [
    checkedIncludeWine,
    checkedNotIncludeWine,
    setCheckedIncludeWine,
    setCheckedNotIncludeWine,
  ]);

  const deleteFromSubscriptionWineList = useCallback(() => {
    let checkedNotIncludeWineTemp: IWineInfo[] = [...checkedNotIncludeWine];
    let checkedIncludeWineTemp: IWineInfo[] = [];
    checkedIncludeWine.forEach((item: IWineInfo) => {
      if (item.status % 2 === 1) {
        firstWineData.current[0].find(
          (e: IWineInfo) => e.wineId === item.wineId,
        ) === undefined
          ? (item.status = 2)
          : (item.status = 0);
        checkedNotIncludeWineTemp.push(item);
      } else {
        checkedIncludeWineTemp.push(item);
      }
    });
    setCheckedNotIncludeWine(checkedNotIncludeWineTemp);
    setCheckedIncludeWine(checkedIncludeWineTemp);
  }, [
    checkedIncludeWine,
    checkedNotIncludeWine,
    setCheckedIncludeWine,
    setCheckedNotIncludeWine,
  ]);

  const saveChangeSubsciprionWine = useCallback(async () => {
    try {
      let deleteWineList: number[] = [];
      let insertWineList: number[] = [];
      checkedNotIncludeWine.forEach((item: IWineInfo) => {
        if (item.status === 2 || item.status === 3) {
          deleteWineList.push(item.wineId);
        }
      });
      checkedIncludeWine.forEach((item: IWineInfo) => {
        if (item.status === 4 || item.status === 5) {
          insertWineList.push(item.wineId);
        }
      });
      RegisterDeleteWineInSubscriptionRequest.current.wineIdsToRegister =
        insertWineList;
      RegisterDeleteWineInSubscriptionRequest.current.wineIdsToDelete =
        deleteWineList;
      await handleRegisterDeleteWineInSubscription(
        RegisterDeleteWineInSubscriptionRequest.current,
      );
      Toast.successToast('저장 성공');
      await findSubscription(true);
    } catch (e: any) {
      Toast.errorToast('저장 실패');
    }
  }, [
    checkedIncludeWine,
    checkedNotIncludeWine,
    setCheckedIncludeWine,
    setCheckedNotIncludeWine,
  ]);

  const getWineList = useCallback(async () => {
    try {
      const { data } = await handleGetWineList();
      return data;
    } catch (e: any) {
      Toast.errorToast('통신환경을 확인해주세요.');
    }
  }, []);

  const findSubscription = useCallback(
    async (isSecond?: boolean) => {
      try {
        const data = await handleGetSubscriptionList(
          checkedMonth.value,
          checkedSubscription.name,
        );
        let includeWineTemp: IWineInfo[] = [];
        data.data[0]?.wines.forEach((item: IWineInfo) => {
          includeWineTemp.push({ ...item, status: 0 });
        });
        setCheckedIncludeWine(includeWineTemp);

        const wineData: any = await getWineList();
        let notIncludeWineTemp: IWineInfo[] = [];
        wineData.forEach((item: IWineInfo) => {
          if (
            includeWineTemp.find((e) => e.wineId === item.wineId) === undefined
          ) {
            notIncludeWineTemp.push({ ...item, status: 0 });
          }
        });
        setCheckedNotIncludeWine(notIncludeWineTemp);
        RegisterDeleteWineInSubscriptionRequest.current.subscriptionId =
          data.data[0].subscriptionId;
        firstWineData.current = [notIncludeWineTemp, includeWineTemp];
        if (!isSecond) {
          Toast.successToast('조회 성공');
        }
      } catch (e: any) {
        Toast.errorToast('조회 실패');
      }
    },
    [
      checkedMonth,
      checkedSubscription,
      checkedIncludeWine,
      setCheckedIncludeWine,
      checkedNotIncludeWine,
      setCheckedNotIncludeWine,
    ],
  );

  return {
    onChangeSelectBoxMonthItem,
    checkedMonth,
    checkedSubscription,
    onChangeSelectBoxSubscriptionItem,
    checkWineList,
    checkedNotIncludeWine,
    setCheckedNotIncludeWine,
    checkedIncludeWine,
    setCheckedIncludeWine,
    insertToSubscriptionWineList,
    deleteFromSubscriptionWineList,
    saveChangeSubsciprionWine,
    findSubscription,
    checkInit,
  };
};

export default useSubscription;
