import { memo } from 'react';
import { StyledTableHead } from './styles';

const TableHead = ({ titles }: { titles: any }) => {
  // Props로 string 배열을 받아와서 head로 만듦
  const ThTitles: JSX.Element[] = titles.map((title: any, index: number) => (
    <th
      key={index}
      style={{width: String(title.value) + '%'}}
    >
      {title.text}
    </th>
  ));

  return (
    <StyledTableHead>
      <tr>{ThTitles}</tr>
    </StyledTableHead>
  );
};

export default memo(TableHead);
