import { atom } from 'recoil';
import { IFAQInfo } from 'types/FAQ';

export const getFAQListAtom = atom<IFAQInfo[]>({
  key: 'getFAQListAtom',
  default: [],
});

export const checkedFAQInfoAtom = atom<IFAQInfo>({
  key: 'checkedFAQInfoAtom',
  default: {
    faqId: 0,
    question: '',
    answer: '',
    ordering: 0,
    category: '',
  },
});

export const orderingAtom = atom<number>({
  key: 'orderingAtom',
  default: 0,
});
