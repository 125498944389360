import { useState, useRef, ChangeEvent, useCallback } from 'react';
import {
  handleGetYoutubeList,
  handleUpdateYoutubeList,
} from 'libs/apis/youtube.api';
import Toast from 'libs/Toast';
import { IYoutubeInfo, IYoutubeUrlUpdateRequest } from 'types/Youtube.type';

const useYoutubeManagement = () => {
  const [youtubeList, setYoutubeList] = useState<IYoutubeInfo[]>();
  const youtubeListValue = useRef<IYoutubeInfo[]>();

  const getYoutubeList = useCallback(async () => {
    try {
      const { data } = await handleGetYoutubeList();
      setYoutubeList(data);
      youtubeListValue.current = data;
    } catch (e: any) {
      Toast.errorToast('통신환경을 확인해주세요.');
    }
  }, []);

  const saveYoutubeList = async () => {
    try {
      let youtubeUrlUpdateRequest: IYoutubeUrlUpdateRequest[] = [
        {
          youtubeShortsId: youtubeListValue.current![0].youtubeShortsId,
          youtubeShortsUrl: youtubeListValue.current![0].youtubeShortsUrl,
        },
        {
          youtubeShortsId: youtubeListValue.current![1].youtubeShortsId,
          youtubeShortsUrl: youtubeListValue.current![1].youtubeShortsUrl,
        },
        {
          youtubeShortsId: youtubeListValue.current![2].youtubeShortsId,
          youtubeShortsUrl: youtubeListValue.current![2].youtubeShortsUrl,
        },
      ];
      await handleUpdateYoutubeList(youtubeUrlUpdateRequest);
      Toast.successToast('수정 성공');
    } catch (e: any) {
      Toast.errorToast('수정 실패');
    }
  };

  return {
    youtubeList,
    getYoutubeList,
    saveYoutubeList,
    youtubeListValue,
  };
};

export default useYoutubeManagement;
