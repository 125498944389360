import { IDashboardInfo } from "types/Dashboard.type";
import { useState, useCallback } from "react";
import { handleGetDashboard } from "libs/apis/dashboard.api";
import Toast from 'libs/Toast';

const useMain = () => {
    const [dashboard, setDashboard] = useState<IDashboardInfo>();

    const getDashboard = useCallback(async () => {
        try {
            const curMonth = new Date().getMonth() + 1;
            const {data} = await handleGetDashboard(curMonth);
            setDashboard(data);
        } catch (e : any) {
            Toast.errorToast('통신환경을 확인해주세요.');
        }
      },[]);
    
    return {
        dashboard,
        setDashboard,
        getDashboard
    };
}

export default useMain;