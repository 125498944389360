import { memo } from 'react';
import { StyledTableRow } from './styles';

interface Props {
  children: JSX.Element[];
  color?: string;
}

const TableRow = ({ children, color }: Props) => {
  // 테이블값으로 컴포넌트가 들어오는것을 감안해서 children으로 props를 받아옴
  const TableCellList: JSX.Element[] = children.map((item, index) => (
    <td key={index}>{item}</td>
  ));
  return <StyledTableRow color={color}>{TableCellList}</StyledTableRow>;
};

export default memo(TableRow);
