import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: 66px 22px 22px 22px;
  margin-left: 216px;
  height: calc(100vh - 46px);
  width: calc(100vw - 216px);
`;

export const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
  margin-bottom: 12px;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledSaveButton = styled.button`
  width: 5%;
  height: 80%;
  background-color: ${(props) => props.theme.mainColor};
  outline: 0;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledItemWrapper = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const StyledItemText = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledItemInputBox = styled.input`
  height: 50%;
  width: 70%;
`;

export const StyledCheckbox = styled.input`
  width: 26px;
  height: 26px;
  margin: 0;
`;
