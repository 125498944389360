import Toast from 'libs/Toast';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState, ChangeEvent, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { IFAQInfo, IFAQOrderUpdateRequest } from 'types/FAQ';
import { getFAQListAtom, checkedFAQInfoAtom, orderingAtom } from 'store/faq';
import {
  handleGetFAQList,
  handleInsertFAQ,
  handleDeleteFAQ,
  handleEditFAQ,
  handleEditFAQOrder,
} from 'libs/apis/faq.qpi';
import { ISelectBoxItem } from 'types/SelectBox.type';
import { FAQCategoryList } from 'constants/constants';

const useFAQ = () => {
  const navigate = useNavigate();
  const [faqList, setFaqList] = useState<IFAQInfo[]>([]);
  const [checkedFAQInfo, setCheckedFAQInfo] = useState<IFAQInfo>({
    faqId: 0,
    question: '',
    answer: '',
    ordering: 0,
    category: '',
  });
  const [checkedCategory, setCheckedCategory] = useState<any>({
    name: '주문/결제',
    value: 1,
  });
  const [orderLastNumber, setOrderLastNumber] =
    useRecoilState<number>(orderingAtom);

  const onChangeSelectBoxCategoryItem = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
      setCheckedCategory({
        name: FAQCategoryList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.name,
        value: FAQCategoryList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.value,
      });
    },
    [checkedCategory, setCheckedCategory],
  );

  const getFAQList = useCallback(async (): Promise<void> => {
    try {
      const { data } = await handleGetFAQList(checkedCategory.name);
      setFaqList(data);
      let orderLastNumberTemp = 0;
      data.forEach((item: IFAQInfo) => {
        if (item.ordering > orderLastNumberTemp) {
          orderLastNumberTemp = item.ordering;
        }
      });
      setOrderLastNumber(orderLastNumberTemp);
    } catch (e: any) {
      Toast.errorToast('통신 실패');
    }
  }, [checkedCategory, setCheckedCategory]);

  const insertFAQ = useCallback(
    async (data: IFAQInfo): Promise<void> => {
      try {
        data.category = checkedCategory.name;
        data.ordering = orderLastNumber + 1;
        await handleInsertFAQ(data);
        Toast.successToast('등록 성공');
        navigate(-1);
      } catch (e: any) {
        Toast.errorToast('등록 실패');
      }
    },
    [orderLastNumber, checkedCategory, setOrderLastNumber, setCheckedCategory],
  );

  const deleteFAQ = useCallback(async (): Promise<void> => {
    try {
      await handleDeleteFAQ(checkedFAQInfo.faqId);
      // setOpenUploadModal(false);
      Toast.successToast('삭제 성공');
    } catch (e: any) {
      Toast.errorToast('삭제 실패');
    }
  }, [checkedFAQInfo, setCheckedFAQInfo]);

  const editFAQ = useCallback(
    async (data: IFAQInfo): Promise<void> => {
      try {
        await handleEditFAQ(data);
        Toast.successToast('수정 성공');
        navigate(-1);
      } catch (e: any) {
        Toast.errorToast('수정 실패');
      }
    },
    [checkedFAQInfo, setCheckedFAQInfo],
  );

  const editOrderFAQ = useCallback(
    (type: number) => {
      let faqListTemp: IFAQInfo[] = [...faqList];
      if (type === 1) {
        faqListTemp.find((e) => e.faqId === checkedFAQInfo.faqId)!.ordering = 1;
        let orderCount = 2;
        faqListTemp.forEach((item: IFAQInfo) => {
          if (item.faqId !== checkedFAQInfo.faqId) {
            item.ordering = orderCount;
            orderCount++;
          }
        });
        faqListTemp.sort((a: IFAQInfo, b: IFAQInfo) => {
          return a.ordering - b.ordering;
        });
        setFaqList(faqListTemp);
      } else if (type === 2) {
        faqListTemp.find(
          (e) =>
            e.faqId ===
            faqListTemp.find((e) => e.ordering === checkedFAQInfo.ordering - 1)!
              .faqId,
        )!.ordering++;
        faqListTemp.find((e) => e.faqId === checkedFAQInfo.faqId)!.ordering--;
        faqListTemp.sort((a: IFAQInfo, b: IFAQInfo) => {
          return a.ordering - b.ordering;
        });
        setFaqList(faqListTemp);
      } else if (type === 3) {
        faqListTemp.find(
          (e) =>
            e.faqId ===
            faqListTemp.find((e) => e.ordering === checkedFAQInfo.ordering + 1)!
              .faqId,
        )!.ordering--;
        faqListTemp.find((e) => e.faqId === checkedFAQInfo.faqId)!.ordering++;
        faqListTemp.sort((a: IFAQInfo, b: IFAQInfo) => {
          return a.ordering - b.ordering;
        });
        setFaqList(faqListTemp);
      } else {
        faqListTemp.find((e) => e.faqId === checkedFAQInfo.faqId)!.ordering =
          orderLastNumber;
        let orderCount = 1;
        faqListTemp.forEach((item: IFAQInfo) => {
          if (item.faqId !== checkedFAQInfo.faqId) {
            item.ordering = orderCount;
            orderCount++;
          }
        });
        faqListTemp.sort((a: IFAQInfo, b: IFAQInfo) => {
          return a.ordering - b.ordering;
        });
        setFaqList(faqListTemp);
      }
    },
    [faqList, setFaqList, checkedFAQInfo, setCheckedFAQInfo],
  );

  const editOrderSave = useCallback(async () => {
    try {
      let faqRequestBody: IFAQOrderUpdateRequest[] = [];
      faqList.forEach((item: IFAQInfo) => {
        let faqRequestBodyTemp: IFAQOrderUpdateRequest = {
          faqId: item.faqId,
          ordering: item.ordering,
        };
        faqRequestBody.push(faqRequestBodyTemp);
      });

      await handleEditFAQOrder(faqRequestBody);
      Toast.successToast('수정 성공');
    } catch (e: any) {
      Toast.errorToast('수정 실패');
    }
  }, [faqList, setFaqList, checkedFAQInfo, setCheckedFAQInfo]);

  return {
    faqList,
    setFaqList,
    checkedFAQInfo,
    setCheckedFAQInfo,
    getFAQList,
    insertFAQ,
    deleteFAQ,
    editFAQ,
    onChangeSelectBoxCategoryItem,
    checkedCategory,
    editOrderFAQ,
    editOrderSave,
  };
};

export default useFAQ;
