import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: 66px 22px 22px 22px;
  margin-left: 216px;
  height: calc(100vh - 46px);
  width: calc(100vw - 216px);
`;

export const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
  margin-bottom: 12px;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 10%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
`;

export const StyledRowWrapper = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const StyledRowTitle = styled.div`
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const StyledRowInputBox = styled.input`
  width: 50%;
  height: 90%;
  white-space: pre-wrap;
`;

export const StyledText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
  margin-right: 5px;
`;

export const StyledSelectBoxWrapper = styled.div`
  width: 90%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;
