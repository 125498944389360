import styled from 'styled-components';

export const StyledTableRow = styled.tr<{ color?: string }>`
  & > td {
    padding: 8px;
    height: 60px;
    border: 1px solid ${({ theme }) => theme.darkGray};
    vertical-align: middle;
    text-align: center;
    background-color: ${(props) =>
      props.color ? props.color : props.theme.weight};
  }
`;
