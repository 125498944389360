export const getNowDateFormat = () => {
  let nowDateTemp = new Date();
  const nowDate =
    nowDateTemp.getFullYear() +
    '-' +
    ('0' + (nowDateTemp.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + nowDateTemp.getDate()).slice(-2);
  return nowDate;
};

export const getDateSubtract = () => {
  const loginDateString = String(localStorage.getItem('loginDate'));
  const loginDate = new Date(loginDateString);
  const currentDate = new Date();
  return currentDate.getDate() - loginDate.getDate();
}