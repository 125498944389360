import axios, { AxiosInstance } from 'axios';

export const customAxios: AxiosInstance = axios.create({
  //baseURL: 'http://127.0.0.1:8080/api/v1',
   baseURL: 'https://api.winelotteshopping.com/api/v1',
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: ('Bearer ' + localStorage.getItem('token')) as string,
  },
});

export default customAxios;
