import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Toast from 'libs/Toast';
import { getUserInfoAtom } from 'store/user';

const useNav = () => {
  const navigate = useNavigate();
  const userList = useRecoilValue(getUserInfoAtom);
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));

  const logout = useCallback((): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    window.location.href = '/';
  }, []);

  const authorityCheck = useCallback(async (): Promise<void> => {
    try {
      const token: string | null = localStorage.getItem('token');
      if (token == null || token === 'undefined') {
        window.location.replace('/');
        return;
      }
    } catch (e: any) {
      if (e?.response?.data?.status === 10) {
        Toast.errorToast('토큰이 만료되었습니다. 다시로그인해주세요!');
        navigate('/');
      }
    }
  }, [navigate, userList]);

  return {
    logout,
    authorityCheck,
    userList,
    userInfo
  };
};

export default useNav;
