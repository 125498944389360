import { atom } from 'recoil';
import { IUserInfo } from 'types/User.type';

export const getUserListAtom = atom<IUserInfo[]>({
  key: 'getUserListAtom',
  default: [],
});

export const getUserInfoAtom = atom<IUserInfo>({
  key: 'getUserInfoAtom',
  default: {
    userId: 0,
    email: '',
    userName: '',
    recommendationId: 0,
    subscriptionIdList: '',
    subscriptionTypeList: '',
  },
})