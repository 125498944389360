import customAxios from 'libs/CustomAxios';
import { IResponse } from 'types/Response.type';
import { IGetWineListReponse, IWineInfo } from 'types/Wine.type';

export const handleGetWineList = async (): Promise<IGetWineListReponse> => {
  const url: string = `/wine/find`;
  const { data } = await customAxios.get(url);
  return data;
};

export const handleInsertWine = async (
  reqTemp: IWineInfo,
  imgName: any,
): Promise<IResponse> => {
  const url: string = `/wine/insert`;
  let req = {
    ...reqTemp,
    imgName: imgName,
  };
  const { data } = await customAxios.post(url, req);
  return data.data;
};

export const handleDeleteWine = async (wineId: number): Promise<IResponse> => {
  const url: string = `/wine/delete?wineId=${wineId}`;
  const { data } = await customAxios.delete(url);
  return data;
};

export const handleEditWine = async (req: IWineInfo): Promise<IResponse> => {
  const url: string = `/wine/update`;
  const { data } = await customAxios.post(url, req);
  return data;
};
