import customAxios from 'libs/CustomAxios';
import { IResponse } from 'types/Response.type';
import {
  IVoucherDescription,
  IGetVoucherListReponse,
  IGetVoucherListRequest,
} from 'types/Voucher.type';

export const handleGetVoucherList = async (
  req: IGetVoucherListRequest,
  page: number,
  size: number,
): Promise<IGetVoucherListReponse> => {
  const url: string = `/voucher/search?page=${page}&size=${size}`;
  const { data } = await customAxios.post(url, req);
  return data;
};

export const handleUpdateDescription = async (
  req: IVoucherDescription[],
): Promise<any> => {
  const url: string = `/voucher/description`;
  const updateElementList = {
    updateElementList: req,
  };
  const { data } = await customAxios.post(url, updateElementList);
  return data;
};

export const handleCancel = async (voucherId: number): Promise<void> => {
  const url: string = `/voucher/cancel?voucherId=${voucherId}`;
  const { data } = await customAxios.get(url);
  return data;
};
