import { useCallback, useRef, useState, ChangeEvent, useEffect } from 'react';
import useUpload from 'hooks/useUpload';
import Toast from 'libs/Toast';
import { useNavigate } from 'react-router-dom';
import { IPopUp } from 'types/Common.type';
import { handleInsertPopup, handleGetPopUpIamge, handleSetEnablePopUPImage } from 'libs/apis/popup.api';

const useNotice = () => {
  const navigate = useNavigate();
  const [imgFile, setImgFile] = useState<any>('');
  const imgRef = useRef<any>();
  const { handleFileInput } = useUpload();
  let imgForUpload: any;
  const [onload, setOnload] = useState(false);
  const itemsList = [
    { value: 1, name: 'N' },
    { value: 2, name: 'Y' },
  ];
  const [selectedItem, setSelectedItem] = useState<any>({
    value: 1,
    name: 'N',
  });
  const [alreadyData, setAlreadyData] = useState<IPopUp>({
    image: '',
    endDate: '',
    enable: '',
  });

  const saveImgFile = useCallback((e: any) => {
    imgForUpload = e.target.files[0];
    const file = imgRef.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgFile(reader.result);
    };
  }, []);

  const getIamge = async () => {
    const data: IPopUp = await handleGetPopUpIamge();
    setAlreadyData(data);
    setSelectedItem({
      value: (data.enable === 'Y' ? 2 : 1),
      name: data.enable
    });
  };

  useEffect(() =>{
    handleSetEnablePopUPImage(selectedItem.name);
  }, [selectedItem, setSelectedItem]);


  const insertImage = useCallback(async (): Promise<void> => {
    const d = new Date().toISOString();
    try {
      const data: IPopUp = {
        image:
          imgFile === '' ? alreadyData.image : imgFile,
        endDate: '2023-06-08',
        enable: selectedItem.name,
      };
      console.log("data :", data);
      await handleInsertPopup(data);
      console.log(imgForUpload);
      await handleFileInput(
        imgForUpload,
        'https://wine-project-prod.s3.ap-northeast-2.amazonaws.com/notice/' + imgFile === '' ? alreadyData.image : imgFile,
      );
      Toast.successToast('등록 성공');
    } catch (e: any) {
      Toast.errorToast('등록 실패');
      console.log(e);
    }
  }, [selectedItem, setSelectedItem]);

  const onChangeSelectBoxItem = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
      setSelectedItem({
        name: itemsList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.name,
        value: itemsList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.value,
      });
    },
    [selectedItem, setSelectedItem],
  );

  return {
    saveImgFile,
    imgRef,
    imgFile,
    onload,
    setOnload,
    setImgFile,
    insertImage,
    selectedItem,
    itemsList,
    onChangeSelectBoxItem,
    getIamge,
    alreadyData,
  };
};

export default useNotice;
