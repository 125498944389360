import {
  StyledButtonWrapper,
  StyledInputWrapper,
  StyledLoginPage,
  StyledLogo,
} from './styles';
import useLogin from 'hooks/useLogin';
import { useNavigate } from 'react-router-dom';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { ChangeEvent, memo, useEffect } from 'react';

const Login = () => {
  const { info, setInfo, handleLogin, handleKeyPress } = useLogin();

  useEffect(() => {
    sessionStorage.removeItem('location');
  }, []);

  return (
    <StyledLoginPage>
      <StyledLogo>
        <span>WINE</span>
        <span>와인시스템 관리자 페이지</span>
      </StyledLogo>
      <StyledInputWrapper>
        <Input
          value={info.email}
          placeholder="아이디"
          type="text"
          handleChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInfo({ ...info, email: e.target.value })
          }
          handleKeyPress={(e) => handleKeyPress(e)}
          isAutoFocus={true}
        />
        <Input
          value={info.password}
          placeholder="비밀번호"
          type="password"
          handleChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInfo({ ...info, password: e.target.value })
          }
          handleKeyPress={(e) => handleKeyPress(e)}
        />
      </StyledInputWrapper>
      <StyledButtonWrapper>
        <Button
          fontSize="0.875rem"
          content="로그인"
          handleClick={() => handleLogin()}
        />
      </StyledButtonWrapper>
    </StyledLoginPage>
  );
};

export default memo(Login);
