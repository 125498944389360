import { StyledTitle } from './styles';
import { memo } from 'react';

const SideBarWrapper = ({
  children,
  title,
}: {
  title: string;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <>
      <StyledTitle>
        <span>{title}</span>
      </StyledTitle>
      {children}
    </>
  );
};

export default memo(SideBarWrapper);
