import useNav from 'hooks/useNav';
import { memo, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Logout,
  NavLogo,
  NavTitle,
  StyledLogo,
  StyledNav,
  StyledUserInfoWrapper,
  StyledUserInfoText,
} from './styles';
import MainLogo from 'assets/icons/Mainlogo.png';

const Nav = () => {
  const navigate = useNavigate();
  const { logout, authorityCheck, userList, userInfo } = useNav();

  useLayoutEffect(() => {
    // authorityCheck();
  }, [authorityCheck]);

  return (
    <StyledNav>
      <StyledLogo onClick={() => navigate('/main')}>
        <NavLogo src={MainLogo} />
        <NavTitle>와인시스템</NavTitle>
      </StyledLogo>
      <StyledUserInfoWrapper>
        <StyledUserInfoText>
          {'현재 ' + userInfo.userName + ' 님 로그인 상태입니다'}
        </StyledUserInfoText>
      </StyledUserInfoWrapper>
      <Logout onClick={() => logout()}>로그아웃</Logout>
    </StyledNav>
  );
};

export default memo(Nav);
