import customAxios from 'libs/CustomAxios';
import { IResponse } from 'types/Response.type';
import {
  IGetUserListReponse,
  IGetUserListAndCountReponseResult,
} from 'types/User.type';

export const handleGetUserList = async (
  page: number,
  size: number,
  name: string,
  id: string,
): Promise<IGetUserListAndCountReponseResult> => {
  const url: string = `/user?page=${page}&size=${size}&name=${name}&id=${id}`;
  const { data } = await customAxios.get(url);
  return data;
};

export const handleInitPassword = async (email: string): Promise<IResponse> => {
  const url: string = `/auth/password/reset`;
  const req = {
    email: email,
  };
  const { data } = await customAxios.post(url, req);
  return data;
};
