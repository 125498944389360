import { atom } from 'recoil';
import { IWineInfo } from 'types/Wine.type';

export const getWineistAtom = atom<IWineInfo[]>({
  key: 'getWineistAtom',
  default: [],
});

export const checkedWineInfoAtom = atom<IWineInfo>({
  key: 'checkedWineInfoAtom',
  default: {
    wineId: 0,
    country: '',
    kind: '',
    detail: '',
    url: '',
    nameEn: '',
    nameKr: '',
    degree: '',
    race: '',
    amount: 0,
    status: 0,
  },
});
