import {
  StyledWrapper,
  StyledTitle,
  StyledButtonWrapper,
  StyledRowWrapper,
  StyledRowTitle,
  StyledRowInputBox,
  StyledImgFileUpload,
  StyledImgPreview,
} from './styles';
import Button from 'components/common/Button';
import useQueryString from 'hooks/useQueryString';
import { memo, useEffect } from 'react';
import useWine from 'hooks/useWine';

const WineEdit = () => {
  const { type } = useQueryString();
  const {
    checkedWineInfo,
    insertWineInfo,
    editWineInfo,
    saveImgFile,
    imgRef,
    imgFile,
    wineInfoTempRef,
    onload,
    setOnload,
    setImgFile,
  } = useWine();

  useEffect(() => {
    if (type === 'edit') {
      wineInfoTempRef.current = {
        wineId: checkedWineInfo.wineId,
        country: checkedWineInfo.country,
        kind: checkedWineInfo.kind,
        detail: checkedWineInfo.detail,
        url: checkedWineInfo.url,
        nameEn: checkedWineInfo.nameEn,
        nameKr: checkedWineInfo.nameKr,
        degree: checkedWineInfo.degree,
        race: checkedWineInfo.race,
        amount: checkedWineInfo.amount,
        status: 0,
      };
      wineInfoTempRef.current.url =
        'https://wine-project-prod.s3.ap-northeast-2.amazonaws.com/wine/' +
        wineInfoTempRef.current.url;
      setImgFile(wineInfoTempRef.current.url);
      setOnload(!onload);
    }
  }, []);

  const GetStyledRow = (title: string) => {
    return (
      <StyledRowWrapper>
        <StyledRowTitle>{title}</StyledRowTitle>
        {title === '사진' ? (
          <StyledImgFileUpload
            type="file"
            accept="image/*"
            onChange={saveImgFile}
            ref={imgRef}
          />
        ) : (
          <StyledRowInputBox
            name={title}
            onChange={(e) => {
              title === '와인명(한글)'
                ? (wineInfoTempRef.current.nameKr = e.target.value)
                : title === '와인명(영어)'
                ? (wineInfoTempRef.current.nameEn = e.target.value)
                : title === '원산지'
                ? (wineInfoTempRef.current.country = e.target.value)
                : title === '종류'
                ? (wineInfoTempRef.current.kind = e.target.value)
                : title === '품종'
                ? (wineInfoTempRef.current.race = e.target.value)
                : title === '용량'
                ? (wineInfoTempRef.current.amount = Number(e.target.value))
                : title === '도수'
                ? (wineInfoTempRef.current.degree = e.target.value)
                : (wineInfoTempRef.current.detail = e.target.value);
            }}
            defaultValue={
              title === '와인명(한글)'
                ? wineInfoTempRef.current.nameKr
                : title === '와인명(영어)'
                ? wineInfoTempRef.current.nameEn
                : title === '원산지'
                ? wineInfoTempRef.current.country
                : title === '종류'
                ? wineInfoTempRef.current.kind
                : title === '품종'
                ? wineInfoTempRef.current.race
                : title === '용량'
                ? wineInfoTempRef.current.amount === 0
                  ? undefined
                  : wineInfoTempRef.current.amount
                : title === '설명'
                ? wineInfoTempRef.current.detail
                : title === '도수'
                ? wineInfoTempRef.current.degree
                : undefined
            }
            type={title === '용량' ? 'number' : undefined}
          />
        )}
      </StyledRowWrapper>
    );
  };

  const WineComponent = () => {
    return (
      <>
        <StyledButtonWrapper>
          <Button
            content={type === 'edit' ? '수정' : '등록'}
            height="40%"
            width="8%"
            handleClick={() => {
              if (type === 'edit') {
                editWineInfo(wineInfoTempRef.current);
              } else {
                insertWineInfo(wineInfoTempRef.current);
              }
            }}
          />
        </StyledButtonWrapper>
        {GetStyledRow('와인명(한글)')}
        {GetStyledRow('와인명(영어)')}
        {GetStyledRow('원산지')}
        {GetStyledRow('종류')}
        {GetStyledRow('품종')}
        {GetStyledRow('용량')}
        {GetStyledRow('도수')}
        {GetStyledRow('설명')}
        {GetStyledRow('사진')}
      </>
    );
  };

  return (
    <StyledWrapper>
      <StyledTitle>와인 정보</StyledTitle>
      {onload}
      <WineComponent />
      <StyledImgPreview src={imgFile ? imgFile : undefined} />
    </StyledWrapper>
  );
};

export default memo(WineEdit);
