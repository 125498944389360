import { IThemeConfig } from 'styled-components';

export const ThemeConfig: IThemeConfig = {
  mainColor: '#e83845',
  black: '#000000',
  white: '#ffffff',
  gray: '#ebedee',
  navBg: '#ffc078',
  lightMainColor: '#fdf1e8',
  darkGray: '#e7e6e6',
  fontGray: '#a5a5a5',
  interfaceBg: '#f7f9fc',
  red: '#FF0000',
};
