import { StyledSideBar } from './styles';
import { NavLink } from 'react-router-dom';
import SideBarWrapper from './SideBarWrapper';
import useQueryString from 'hooks/useQueryString';
import { memo } from 'react';

const SideBar = () => {
  const { status } = useQueryString();

  return (
    <StyledSideBar>
      <SideBarWrapper title="항목">
        {/* <NavLink
          to="/sommelier"
          className={
            status === 'sommelier'
              ? 'nested-nav-link-active'
              : 'nested-nav-link'
          }>
          소믈리에 관리
        </NavLink> */}
        <NavLink
          to="/wine"
          className={
            status === 'wine' ? 'nested-nav-link-active' : 'nested-nav-link'
          }>
          와인 관리
        </NavLink>
        <NavLink
          to="/subscription"
          className={
            status === 'subscription'
              ? 'nested-nav-link-active'
              : 'nested-nav-link'
          }>
          구독권 관리
        </NavLink>
        <NavLink
          to="/faq"
          className={
            status === 'faq' ? 'nested-nav-link-active' : 'nested-nav-link'
          }>
          FAQ 관리
        </NavLink>
        <NavLink
          to="/youtubeManagement"
          className={
            status === 'youtubeManagement'
              ? 'nested-nav-link-active'
              : 'nested-nav-link'
          }>
          영상 관리
        </NavLink>
        <NavLink
          to="/voucher"
          className={
            status === 'voucher' ? 'nested-nav-link-active' : 'nested-nav-link'
          }>
          교환권 관리
        </NavLink>
        <NavLink
          to="/user"
          className={
            status === 'user' ? 'nested-nav-link-active' : 'nested-nav-link'
          }>
          계정 관리
        </NavLink>
        <NavLink
          to="/notice"
          className={
            status === 'notice' ? 'nested-nav-link-active' : 'nested-nav-link'
          }>
          팝업 관리
        </NavLink>
      </SideBarWrapper>
    </StyledSideBar>
  );
};

export default memo(SideBar);
