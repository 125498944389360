import { useCallback, useRef, useState, ChangeEvent } from 'react';
import { useResetRecoilState, useRecoilState } from 'recoil';
import {
  IVoucherInfo,
  IGetVoucherListResponse,
  IGetVoucherListRequest,
} from 'types/Voucher.type';
import { getVoucherListAtom } from 'store/voucher';
import Toast from 'libs/Toast';
import {
  handleGetVoucherList,
  handleUpdateDescription,
  handleCancel,
} from 'libs/apis/voucher.api';
import { IVoucherDescription } from 'types/Voucher.type';
import { MonthList } from 'constants/constants';

const useVoucher = () => {
  const [voucherList, setVoucherList] =
    useRecoilState<IGetVoucherListResponse[]>(getVoucherListAtom);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [allCount, setAllCount] = useState<number>(0);
  const nameValue = useRef<string>('');
  const idValue = useRef<string>('');
  const monthValue = useRef<number>(0);
  const textValue = useRef<IVoucherDescription[]>([]);
  const [onLoad, setOnLoad] = useState<boolean>(false);
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const voucherIdValidation = useRef<number>(0);
  const [isUsed, setIsUsed] = useState<boolean>(true);
  const [isNotUsed, setIsNotUsed] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [checkedMonth, setCheckedMonth] = useState<any>({
    name: '5월',
    value: 5,
  });

  const getVoucherList = useCallback(async () => {
    try {
      const req: IGetVoucherListRequest = {
        code: '',
        userName: nameValue.current,
        userMail: idValue.current,
        month: monthValue.current === 16 ? 4 : monthValue.current,
        subscriptionName: '',
        status: '',
        isUsed: isUsed,
        isNotUsed: isNotUsed,
        isExpired: isExpired
      };

      textValue.current = [];
      const { data } = await handleGetVoucherList(req, pageIndex, 10);
      data.voucherSearchResponseList.forEach(
        (item: IGetVoucherListResponse) => {
          const temp: IVoucherDescription = {
            voucherId: item.voucherId,
            description: item.description === null ? '' : item.description,
          };
          textValue.current.push(temp);
        },
      );
      setVoucherList(data.voucherSearchResponseList);
      setAllCount(data.allCount);
    } catch (e: any) {
      Toast.errorToast('통신환경을 확인해주세요.');
    }
  }, [
    pageIndex,
    setPageIndex,
    allCount,
    setAllCount,
    voucherList,
    setVoucherList,
    nameValue,
    idValue,
    monthValue,
    textValue,
    isUsed,
    isNotUsed,
    isExpired
  ]);

  const handlerUpdateDescription = useCallback(async () => {
    try {
      await handleUpdateDescription(textValue.current);
      window.location.reload();
    } catch (e: any) {
      Toast.errorToast('저장 실패');
    }
  }, []);

  const handlerCancel = useCallback(async (voucherId: number) => {
    try {
      await handleCancel(voucherId);
      window.location.reload();
    } catch (e: any) {
      Toast.errorToast('취소 실패');
    }
  }, []);

  const onChangeSelectBoxMonthItem = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
      setCheckedMonth({
        name: MonthList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.name,
        value: MonthList.find(
          (item: any) => item.value === Number(e.target.value),
        )?.value,
      });

      monthValue.current = Number(MonthList.find(
        (item: any) => item.value === Number(e.target.value),
      )?.value);
    },
    [checkedMonth, setCheckedMonth, monthValue],
  );

  return {
    voucherList,
    setVoucherList,
    getVoucherList,
    pageIndex,
    setPageIndex,
    allCount,
    nameValue,
    idValue,
    monthValue,
    textValue,
    handlerUpdateDescription,
    handlerCancel,
    userInfo,
    openUploadModal,
    setOpenUploadModal,
    voucherIdValidation,
    isUsed, 
    setIsUsed,
    isNotUsed, 
    setIsNotUsed,
    isExpired, 
    setIsExpired,
    onChangeSelectBoxMonthItem,
    checkedMonth
  };
};

export default useVoucher;
