import { memo } from 'react';
import { StyledSelectBox } from './styles';
import { ISelectBox } from 'types/SelectBox.type';

const SelectBox = ({
  items,
  handleChange,
  width,
  backgroundColor,
  margin,
  padding,
  height,
  borderRadius,
  fontSize,
  color,
  customStyle,
  defaultValue,
  itemRef,
  value,
  isDisabled = false,
}: ISelectBox) => {
  const style = {
    ...customStyle,
    color,
    backgroundColor,
    margin,
    padding,
    fontSize,
    borderRadius,
    height,
    width,
  };

  return (
    <StyledSelectBox
      ref={itemRef}
      onChange={handleChange}
      style={style}
      defaultValue={defaultValue}
      disabled={isDisabled}
      value={value}>
      {items.map((item) =>
        <option key={item.name} value={item.value}>
          {item.name}
        </option>
      )}
    </StyledSelectBox>
  );
};

export default memo(SelectBox);
