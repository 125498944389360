import TableRow from 'components/common/TableRow';
import TableHead from 'components/common/TableHead';
import {
  StyledWrapper,
  StyledTitle,
  StyledTableItemWrap,
  StyledTableItem,
  StyledButtonWrapper,
  StyledCheckbox,
  StyledButtonLink,
  StyledSelectBoxWrapper,
  StyledItemBoxWrapper,
  StyledItemSideBoxWrapper,
  StyledItemMidBoxWrapper,
  StyledText,
} from './styles';
import { useEffect, useState, memo, ChangeEvent, useCallback } from 'react';
import Button from 'components/common/Button';
import useSubscription from 'hooks/useSubscription';
import SelectBox from 'components/common/SelectBox';
import { MonthList, SubscriptionList } from 'constants/constants';
import { IWineInfo } from 'types/Wine.type';

const Subscription = () => {
  const {
    onChangeSelectBoxMonthItem,
    checkedMonth,
    checkedSubscription,
    onChangeSelectBoxSubscriptionItem,
    checkWineList,
    checkedNotIncludeWine,
    setCheckedNotIncludeWine,
    checkedIncludeWine,
    setCheckedIncludeWine,
    insertToSubscriptionWineList,
    deleteFromSubscriptionWineList,
    saveChangeSubsciprionWine,
    findSubscription,
    checkInit,
  } = useSubscription();

  return (
    <StyledWrapper>
      <StyledTitle>구독권 관리</StyledTitle>
      <StyledSelectBoxWrapper>
        <StyledText>월 :</StyledText>
        <SelectBox
          items={MonthList}
          value={checkedMonth.value}
          handleChange={onChangeSelectBoxMonthItem}
          width="30%"
        />
        <StyledText>구독권 종류 :</StyledText>
        <SelectBox
          items={SubscriptionList}
          value={checkedSubscription.value}
          handleChange={onChangeSelectBoxSubscriptionItem}
          width="30%"
        />
        <StyledButtonLink to="/subscription">
          <Button
            content="조회"
            height="40%"
            width="100%"
            handleClick={findSubscription}
          />
        </StyledButtonLink>
      </StyledSelectBoxWrapper>
      <StyledItemBoxWrapper>
        <StyledItemSideBoxWrapper>
          <StyledTableItemWrap>
            <table width="calc(100vw - 260px)">
              <TableHead
                titles={[
                  { text: ' ', value: 10 },
                  { text: '전체 와인명', value: 90 },
                ]}
              />
              <tbody>
                {checkedNotIncludeWine.map((item: IWineInfo) => (
                  <TableRow
                    color={
                      item.status === 2 || item.status === 3
                        ? '#F7916A'
                        : undefined
                    }>
                    <StyledCheckbox
                      type="checkbox"
                      onChange={() => {
                        checkWineList(
                          item,
                          checkedNotIncludeWine,
                          setCheckedNotIncludeWine,
                          item.status,
                        );
                      }}
                      checked={item.status % 2 === 1 ? true : false}
                    />
                    <StyledTableItem>
                      {item.nameEn + ' / ' + item.nameKr}
                    </StyledTableItem>
                  </TableRow>
                ))}
              </tbody>
            </table>
          </StyledTableItemWrap>
        </StyledItemSideBoxWrapper>

        <StyledItemMidBoxWrapper>
          <StyledButtonWrapper>
            <Button
              content="초기화"
              height="15%"
              width="30%"
              fontSize="0.8rem"
              handleClick={checkInit}
            />
            <Button
              content=">>"
              height="30%"
              width="30%"
              backgroundColor="gray"
              fontSize="2rem"
              handleClick={insertToSubscriptionWineList}
            />
            <Button
              content="<<"
              height="30%"
              width="30%"
              backgroundColor="gray"
              fontSize="2rem"
              handleClick={deleteFromSubscriptionWineList}
            />
            <Button
              content="저장"
              height="15%"
              width="30%"
              fontSize="1rem"
              handleClick={saveChangeSubsciprionWine}
            />
          </StyledButtonWrapper>
        </StyledItemMidBoxWrapper>

        <StyledItemSideBoxWrapper>
          <StyledTableItemWrap>
            <table width="calc(100vw - 260px)">
              <TableHead
                titles={[
                  { text: ' ', value: 10 },
                  { text: '와인명', value: 90 },
                ]}
              />
              <tbody>
                {checkedIncludeWine.map((item: IWineInfo) => (
                  <TableRow
                    color={
                      item.status === 4 || item.status === 5
                        ? '#ADF8F0'
                        : undefined
                    }>
                    <StyledCheckbox
                      type="checkbox"
                      onChange={() => {
                        checkWineList(
                          item,
                          checkedIncludeWine,
                          setCheckedIncludeWine,
                          item.status,
                        );
                      }}
                      checked={item.status % 2 === 1 ? true : false}
                    />
                    <StyledTableItem>
                      {item.nameEn + ' / ' + item.nameKr}
                    </StyledTableItem>
                  </TableRow>
                ))}
              </tbody>
            </table>
          </StyledTableItemWrap>
        </StyledItemSideBoxWrapper>
      </StyledItemBoxWrapper>
    </StyledWrapper>
  );
};

export default memo(Subscription);
