import useMain from 'hooks/useMain';
import {useCallback, useEffect} from 'react';
import { MainWrapper, StyledTitle, StyledMainWrapper, StyledTopWrapper, StyledBottomWrapper, StyledItemWrapper, StyledItemTitleText, StyledItemContentText, StyledItemContent } from './styles';

const Main = () => {
  const {dashboard , setDashboard, getDashboard} = useMain();

  useEffect(() => {
    getDashboard();
  },[]);
  
  return (
    <MainWrapper>
      <StyledTitle>메인화면</StyledTitle>

      <StyledMainWrapper>
        <StyledTopWrapper>
          <StyledItemWrapper>
            <StyledItemTitleText>현 구독 현황</StyledItemTitleText>
            <StyledItemContent>
              <StyledItemContentText>총 가입자 수 : {dashboard?.allUsersCount}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>총 구독자 수 : {dashboard?.allSubscribersCount}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>총 A 구독자 수 : {dashboard?.subscribersCountA}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>총 B 구독자 수 : {dashboard?.subscribersCountB}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>총 C구독자 수 : {dashboard?.subscribersCountC}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>총 D 구독자 수 : {dashboard?.subscribersCountD}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>총 E 구독자 수 : {dashboard?.subscribersCountE}</StyledItemContentText>
            </StyledItemContent>
          </StyledItemWrapper>

          <StyledItemWrapper>
            <StyledItemTitleText>이번 달 구독신청 현황</StyledItemTitleText>
            <StyledItemContent>
              <StyledItemContentText>A (당월/익월) : {dashboard?.subscribersImmediateCountA} / {dashboard?.subscribersLaterCountA}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>B (당월/익월) : {dashboard?.subscribersImmediateCountB} / {dashboard?.subscribersLaterCountB}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>C (당월/익월) : {dashboard?.subscribersImmediateCountC} / {dashboard?.subscribersLaterCountC}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>D (당월/익월) : {dashboard?.subscribersImmediateCountD} / {dashboard?.subscribersLaterCountD}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>E (당월/익월) : {dashboard?.subscribersImmediateCountE} / {dashboard?.subscribersLaterCountE}</StyledItemContentText>
            </StyledItemContent>

            <StyledItemContent> 
              <StyledItemContentText>취소 : {dashboard?.subscribersCanceled}</StyledItemContentText>
            </StyledItemContent>
          </StyledItemWrapper>

          <StyledItemWrapper>
            <StyledItemTitleText>비고</StyledItemTitleText>
          </StyledItemWrapper>
        
        </StyledTopWrapper>

        <StyledBottomWrapper>
          <StyledItemWrapper>
            <StyledItemTitleText>총 교환권 현황</StyledItemTitleText>
            <StyledItemContent>
              <StyledItemContentText>사용 / 총 발급 : {dashboard?.allVoucherCountUsed} / {dashboard?.allVouchersCount}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>A (사용 / 총 발급) : {dashboard?.vouchersUsedCountA} / {dashboard?.vouchersCountA}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>B (사용 / 총 발급) : {dashboard?.vouchersUsedCountB} / {dashboard?.vouchersCountB}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>C (사용 / 총 발급) : {dashboard?.vouchersUsedCountC} / {dashboard?.vouchersCountC}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>D (사용 / 총 발급) : {dashboard?.vouchersUsedCountD} / {dashboard?.vouchersCountD}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>E (사용 / 총 발급) : {dashboard?.vouchersUsedCountE} / {dashboard?.vouchersCountE}</StyledItemContentText>
            </StyledItemContent>
          </StyledItemWrapper>

          <StyledItemWrapper>
            <StyledItemTitleText>이번 달 교환권 현황</StyledItemTitleText>
            <StyledItemContent>
              <StyledItemContentText>사용 / 총 발급 : {dashboard?.allVoucherCountUsedThisMonth} / {dashboard?.allVouchersCountThisMonth}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>A (사용 / 총 발급) : {dashboard?.vouchersUsedCountThisMonthA} / {dashboard?.vouchersCountThisMonthA}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>B (사용 / 총 발급) : {dashboard?.vouchersUsedCountThisMonthB} / {dashboard?.vouchersCountThisMonthB}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>C (사용 / 총 발급) : {dashboard?.vouchersUsedCountThisMonthC} / {dashboard?.vouchersCountThisMonthC}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>D (사용 / 총 발급) : {dashboard?.vouchersUsedCountThisMonthD} / {dashboard?.vouchersCountThisMonthD}</StyledItemContentText>
            </StyledItemContent>
            <StyledItemContent>
              <StyledItemContentText>E (사용 / 총 발급) : {dashboard?.vouchersUsedCountThisMonthE} / {dashboard?.vouchersCountThisMonthE}</StyledItemContentText>
            </StyledItemContent>
          </StyledItemWrapper>
        

          

          <StyledItemWrapper>
            <StyledItemTitleText>비고</StyledItemTitleText>
          </StyledItemWrapper>

        </StyledBottomWrapper>
      </StyledMainWrapper>
    </MainWrapper>
  );
};

export default Main;
