import customAxios from 'libs/CustomAxios';
import { IResponse } from 'types/Response.type';
import {
  IGetSubscriptionListReponse,
  IRegisterDeleteWineInSubscriptionRequest,
} from 'types/Subscription.type';

export const handleGetSubscriptionList = async (
  month: number,
  name: string,
): Promise<IGetSubscriptionListReponse> => {
  const url: string = `/subscription/find?month=${month}&name=${name}`;
  const { data } = await customAxios.get(url);
  return data;
};

export const handleRegisterDeleteWineInSubscription = async (
  req: IRegisterDeleteWineInSubscriptionRequest,
): Promise<IResponse> => {
  console.log(req);
  const url: string = `/subscription/wine/register`;
  const { data } = await customAxios.post(url, req);
  return data.data;
};
