import styled from 'styled-components';

export const MainWrapper = styled.div`
  padding: 22px;
  margin-top: 46px;
  margin-left: 216px;
  height: calc(100vh - 46px);
  width: calc(100vw - 216px);
`;

export const StyledTitle = styled.div`
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
`;

export const StyledMainWrapper = styled.div`
  margin-top: 5%;
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledTopWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBottomWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledItemWrapper = styled.div`
  height: 80%;
  width: 30%;
  display: block;
  align-items: flex-start;
  justify-content: center;
  border: 2px solid black;
  border-radius: 16px;
  background-color: aliceblue;
`

export const StyledItemTitleText = styled.text`
  margin-top: 5%;
  height: 10%;
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  white-space: pre-wrap;
`

export const StyledItemContentText = styled.text`
  margin-top: 5%;
  height: 2%;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  white-space: pre-wrap;
  display: block;
`

export const StyledItemContent = styled.div`
  width: fit-content;
  display: block;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3%;
`