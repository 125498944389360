import Nav from 'components/common/Nav';
import SideBar from 'components/common/SideBar';
import * as Pages from 'pages';
import { Route, Routes } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import useLogin from 'hooks/useLogin';

const App = () => {
  const { checkLoginStatus } = useLogin();

  useLayoutEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  return (
    <>
      {window.location.pathname === '/' || (
        <>
          <Nav />
          <SideBar />
        </>
      )}
      <Routes>
        <Route path="/" element={<Pages.LoginPage />} />
        <Route path="/main" element={<Pages.MainPage />} />
        <Route path="/subscription" element={<Pages.SubscriptionPage />} />
        <Route path="/wine" element={<Pages.WinePage />} />
        <Route path="/wineEdit" element={<Pages.WineEditPage />} />
        <Route path="/faq" element={<Pages.FAQPage />} />
        <Route path="/faqEdit" element={<Pages.FAQEditPage />} />
        <Route
          path="/youtubeManagement"
          element={<Pages.YoutubeManagementPage />}
        />
        <Route path="/voucher" element={<Pages.VoucherPage />} />
        <Route path="/user" element={<Pages.UserPage />} />
        <Route path="/notice" element={<Pages.NoticePage />} />
        <Route path="/*" element={<Pages.NotFoundPage />} />
      </Routes>
    </>
  );
};

export default App;
