import { useCallback, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { IWineInfo } from 'types/Wine.type';
import { getWineistAtom, checkedWineInfoAtom } from 'store/wine';
import {
  handleGetWineList,
  handleInsertWine,
  handleDeleteWine,
  handleEditWine,
} from 'libs/apis/wine.api';
import useUpload from 'hooks/useUpload';
import Toast from 'libs/Toast';
import { useNavigate } from 'react-router-dom';
import { getNowDateFormat } from 'utils/utils';

const useWine = () => {
  const navigate = useNavigate();
  const [wineList, setWineList] = useRecoilState<IWineInfo[]>(getWineistAtom);
  const [checkedWineInfo, setCheckedWineInfo] =
    useRecoilState<IWineInfo>(checkedWineInfoAtom);
  const [imgFile, setImgFile] = useState<any>('');
  const imgRef = useRef<any>();
  const { handleFileInput } = useUpload();
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  let imgForUpload: any;
  const wineInfoTempRef = useRef<IWineInfo>({
    wineId: 0,
    country: '',
    kind: '',
    detail: '',
    url: '',
    nameEn: '',
    nameKr: '',
    degree: '',
    race: '',
    amount: 0,
    status: 0,
  });
  const [onload, setOnload] = useState(false);

  const saveImgFile = useCallback((e: any) => {
    imgForUpload = e.target.files[0];
    const file = imgRef.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgFile(reader.result);
    };
  }, []);

  const getWineList = useCallback(async () => {
    try {
      const { data } = await handleGetWineList();
      setWineList(data);
    } catch (e: any) {
      Toast.errorToast('통신환경을 확인해주세요.');
    }
  }, []);

  const insertWineInfo = useCallback(async (data: IWineInfo): Promise<void> => {
    try {
      const imgName = [getNowDateFormat(), imgForUpload.type.split('/')[1]];
      const imgUrl = await handleInsertWine(data, imgName);
      await handleFileInput(imgForUpload, String(imgUrl));
      Toast.successToast('등록 성공');
      navigate(-1);
    } catch (e: any) {
      Toast.errorToast('등록 실패');
    }
  }, []);

  const checkWine = useCallback(
    async (item: IWineInfo): Promise<void> => {
      checkedWineInfo.wineId === item.wineId
        ? setCheckedWineInfo(wineInfoTempRef.current)
        : setCheckedWineInfo(item);
    },
    [checkedWineInfo, setCheckedWineInfo],
  );

  const deleteWine = useCallback(async (wineId: number): Promise<void> => {
    try {
      await handleDeleteWine(wineId);
      setOpenUploadModal(false);
      window.location.reload();
      Toast.successToast('삭제 성공');
    } catch (e: any) {
      Toast.errorToast('삭제 실패');
    }
  }, []);

  const editWineInfo = useCallback(async (data: IWineInfo): Promise<void> => {
    try {
      data = {
        ...data,
        url: wineInfoTempRef.current.url.split(
          'https://wine-project-prod.s3.ap-northeast-2.amazonaws.com/wine/',
        )[1],
      };
      await handleEditWine(data);
      navigate(-1);
      Toast.successToast('수정 성공');
    } catch (e: any) {
      Toast.errorToast('수정 실패');
    }
  }, []);

  return {
    getWineList,
    wineList,
    checkedWineInfo,
    setCheckedWineInfo,
    insertWineInfo,
    checkWine,
    deleteWine,
    editWineInfo,
    saveImgFile,
    imgRef,
    imgFile,
    wineInfoTempRef,
    openUploadModal,
    setOpenUploadModal,
    onload,
    setOnload,
    setImgFile,
  };
};

export default useWine;
