import { memo, Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  StyledSearchProductFooter,
  StlyedCurrentPage
} from './styles';
import { StyledButton } from 'components/common/Button/styles';

interface Props {
  allCount: number;
  selectedNumber: number;
  setSelectedNumber : (selected : number) => void;
  onClick:  Dispatch<SetStateAction<number>>;
}

const Pagination = ({allCount, selectedNumber, setSelectedNumber, onClick}: Props) => {
  const [minPageIndex, setMinPageIndex] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(9);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const remain = allCount%10;
    setPageCount(allCount / 10 + (remain > 0 ? 1 : 0))
  }, [allCount]);
  

  const handlePrev = () => {
    if (minPageIndex <= 0) return;
    setMinPageIndex(minPageIndex - 10);
    setMaxPageIndex(maxPageIndex - 10);
    setSelectedNumber(minPageIndex - 10);
  }

  const handleNext = () => {
    if (pageCount <= maxPageIndex && pageCount >= minPageIndex) return;
    setMinPageIndex(minPageIndex + 10);
    setMaxPageIndex(maxPageIndex + 10);
    setSelectedNumber(minPageIndex + 10);
  }
  
  const GetStyledPageNumber = () => {
    let list: JSX.Element[] = [];
    const arr = Array(Math.floor(pageCount)).fill(0).map((_, index) => index).filter((_, index) => index >= minPageIndex && index <= maxPageIndex);
    arr.forEach((index: number) => {
      list.push(<StlyedCurrentPage key={index} selected={index === selectedNumber ? true : false} onClick={() => {onClick(index)}}>{index + 1}</StlyedCurrentPage>)
    });
    return list;
  }

  return (
    <StyledSearchProductFooter>
      <StyledButton width='20px' height='35px' onClick={handlePrev}>{'<<'}</StyledButton>
      {GetStyledPageNumber()}
      <StyledButton width='20px' height='35px' onClick={handleNext}>{'>>'}</StyledButton>
    </StyledSearchProductFooter>
  );
};

export default memo(Pagination);
