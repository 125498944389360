import { handleGetUserList, handleInitPassword } from 'libs/apis/user.api';
import Toast from 'libs/Toast';
import { useCallback, useRef, useState } from 'react';
import { useResetRecoilState, useRecoilState } from 'recoil';
import { IGetUserListAndCountReponse, IUserInfo } from 'types/User.type';
import { getUserListAtom } from 'store/user';

const useUser = () => {
  const [userList, setUserList] = useRecoilState<IUserInfo[]>(getUserListAtom);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [allCount, setAllCount] = useState<number>(0);
  const nameValue = useRef<string>('');
  const idValue = useRef<string>('');

  const getUserList = useCallback(async () => {
    try {
      const data = await handleGetUserList(
        pageIndex,
        10,
        nameValue.current,
        idValue.current,
      );
      setUserList(data.data.userListResponse);
      setAllCount(data.data.allCount - 4);
    } catch (e: any) {
      Toast.errorToast('통신환경을 확인해주세요.');
    }
  }, [pageIndex, setPageIndex, allCount, setAllCount, userList, setUserList]);

  const initPassword = async (email: string) => {
    try {
      await handleInitPassword(email);
      Toast.successToast('초기화 완료');
    } catch (e: any) {
      console.log(e);
      Toast.errorToast('초기화 실패');
    }
  };

  return {
    userList,
    getUserList,
    initPassword,
    pageIndex,
    setPageIndex,
    allCount,
    idValue,
    nameValue,
  };
};

export default useUser;
