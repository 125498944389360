import TableRow from 'components/common/TableRow';
import TableHead from 'components/common/TableHead';
import {
  StyledWrapper,
  StyledTitle,
  StyledTableItemWrap,
  StyledTableItem,
  StyledButtonWrapper,
  StyledCheckbox,
  StyledButtonLink,
  StyledModalText,
} from './styles';
import { useEffect, memo } from 'react';
import Button from 'components/common/Button';
import useWine from 'hooks/useWine';
import { IWineInfo } from 'types/Wine.type';
import Portal from 'components/common/Modal/Portal';
import Modal from 'components/common/Modal';

const Wine = () => {
  const {
    wineList,
    getWineList,
    checkedWineInfo,
    setCheckedWineInfo,
    deleteWine,
    openUploadModal,
    setOpenUploadModal,
  } = useWine();

  useEffect(() => {
    setCheckedWineInfo({
      wineId: 0,
      country: '',
      kind: '',
      detail: '',
      url: '',
      nameEn: '',
      nameKr: '',
      degree: '',
      race: '',
      amount: 0,
      status: 0,
    });
    getWineList();
  }, []);

  const ModalContent = () => {
    return (
      <Portal>
        <Modal
          width="460px"
          height="150px"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => deleteWine(checkedWineInfo.wineId)}>
          <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText>
        </Modal>
      </Portal>
    );
  };

  return (
    <StyledWrapper>
      <StyledTitle>와인 관리</StyledTitle>

      <StyledButtonWrapper>
        <StyledButtonLink to="/wineEdit?type=new">
          <Button content="신규" height="40%" width="80%" />
        </StyledButtonLink>
        <StyledButtonLink to="/wineEdit?type=edit">
          <Button content="수정" height="40%" width="80%" />
        </StyledButtonLink>
        <StyledButtonLink to="/wine">
          <Button
            content="삭제"
            height="40%"
            width="80%"
            handleClick={() => {
              setOpenUploadModal(true);
            }}
          />
        </StyledButtonLink>
      </StyledButtonWrapper>

      <StyledTableItemWrap>
        <table width="calc(100vw - 260px)">
          <TableHead
            titles={[
              { text: '', value: 2 },
              { text: '와인명(한글)', value: 10 },
              { text: '와인명(영어)', value: 10 },
              { text: '원산지', value: 10 },
              { text: '종류', value: 10 },
              { text: '품종', value: 10 },
              { text: '용량', value: 10 },
              { text: '도수', value: 10 },
              { text: '설명', value: 20 },
            ]}
          />
          <tbody>
            {wineList.map((item: IWineInfo, index: number) => (
              <TableRow key={item.wineId}>
                <StyledCheckbox
                  type="checkbox"
                  onChange={() => {
                    checkedWineInfo.wineId === item.wineId
                      ? setCheckedWineInfo({
                          wineId: 0,
                          country: '',
                          kind: '',
                          detail: '',
                          url: '',
                          nameEn: '',
                          nameKr: '',
                          degree: '',
                          race: '',
                          amount: 0,
                          status: 0,
                        })
                      : setCheckedWineInfo(item);
                  }}
                  checked={checkedWineInfo.wineId == item.wineId}
                />
                <StyledTableItem>{item.nameKr}</StyledTableItem>
                <StyledTableItem>{item.nameEn}</StyledTableItem>
                <StyledTableItem>{item.country}</StyledTableItem>
                <StyledTableItem>{item.kind}</StyledTableItem>
                <StyledTableItem>{item.race}</StyledTableItem>
                <StyledTableItem>{item.amount}</StyledTableItem>
                <StyledTableItem>{item.degree}</StyledTableItem>
                <StyledTableItem>{item.detail}</StyledTableItem>
              </TableRow>
            ))}
          </tbody>
        </table>
      </StyledTableItemWrap>
      {openUploadModal && ModalContent()}
    </StyledWrapper>
  );
};

export default memo(Wine);
