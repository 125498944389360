import {
  StyledWrapper,
  StyledTitle,
  StyledButtonWrapper,
  StyledRowWrapper,
  StyledRowTitle,
  StyledRowInputBox,
  StyledText,
  StyledSelectBoxWrapper,
} from './styles';
import Button from 'components/common/Button';
import useFAQ from 'hooks/useFAQ';
import useQueryString from 'hooks/useQueryString';
import { memo } from 'react';
import { IFAQInfo } from 'types/FAQ';
import SelectBox from 'components/common/SelectBox';
import { FAQCategoryList } from 'constants/constants';

const FAQEdit = () => {
  const { type } = useQueryString();
  const {
    checkedFAQInfo,
    insertFAQ,
    editFAQ,
    onChangeSelectBoxCategoryItem,
    checkedCategory,
  } = useFAQ();

  let faqInfoTemp: IFAQInfo =
    type === 'edit'
      ? {
          faqId: checkedFAQInfo.faqId,
          question: checkedFAQInfo.question,
          answer: checkedFAQInfo.answer,
          ordering: checkedFAQInfo.ordering,
          category: checkedFAQInfo.category,
        }
      : {
          faqId: 0,
          question: '',
          answer: '',
          ordering: 0,
          category: '',
        };

  const GetStyledRow = (title: string) => {
    return (
      <StyledRowWrapper>
        <StyledRowTitle>{title}</StyledRowTitle>
        <StyledRowInputBox
          name={title}
          onChange={(e) => {
            title === '질문'
              ? (faqInfoTemp.question = e.target.value)
              : (faqInfoTemp.answer = e.target.value);
          }}
          defaultValue={
            type === 'edit'
              ? title === '질문'
                ? faqInfoTemp.question
                : faqInfoTemp.answer
              : undefined
          }
        />
      </StyledRowWrapper>
    );
  };

  const FAQComponent = () => {
    return (
      <>
        {GetStyledRow('질문')}
        {GetStyledRow('답변')}
        <StyledButtonWrapper>
          <Button
            content={type === 'edit' ? '수정' : '등록'}
            height="40%"
            width="8%"
            handleClick={() => {
              if (type === 'edit') {
                editFAQ(faqInfoTemp);
              } else {
                insertFAQ(faqInfoTemp);
              }
            }}
          />
        </StyledButtonWrapper>
      </>
    );
  };

  return (
    <StyledWrapper>
      <StyledTitle>FAQ 정보</StyledTitle>
      <StyledSelectBoxWrapper>
        <StyledText>카테고리 :</StyledText>
        <SelectBox
          items={FAQCategoryList}
          value={checkedCategory.value}
          handleChange={onChangeSelectBoxCategoryItem}
          width="20%"
        />
      </StyledSelectBoxWrapper>
      <FAQComponent />
    </StyledWrapper>
  );
};

export default memo(FAQEdit);
