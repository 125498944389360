import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledWrapper = styled.div`
  padding: 66px 22px 22px 22px;
  margin-left: 216px;
  height: calc(100vh - 46px);
  width: calc(100vw - 216px);
`;

export const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
  margin-bottom: 12px;
`;

export const StyledText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => props.theme.black};
`;

export const StyledTableItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-bottom: 2px solid ${(props) => props.theme.darkGray};
`;

export const StyledTableItem = styled.div<{ maxWidth?: string }>`
  display: inline-block;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
`;

export const StyledCheckbox = styled.input`
  width: 26px;
  height: 26px;
  margin: 0;
`;

export const StyledButtonLink = styled(Link)`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledSelectBoxWrapper = styled.div`
  width: 80%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
`;

export const StyledItemBoxWrapper = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 2px solid ${(props) => props.theme.darkGray};
`;

export const StyledItemSideBoxWrapper = styled.div`
  height: 100%;
  width: 40%;
  border: 2px solid ${(props) => props.theme.darkGray};
`;

export const StyledItemMidBoxWrapper = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButtonWrapper = styled.div`
  width: 80%;
  height: 30%;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
`;
