import { memo, useEffect } from 'react';
import TableRow from 'components/common/TableRow';
import TableHead from 'components/common/TableHead';
import {
  StyledWrapper,
  StyledTitle,
  StyledTableItemWrap,
  StyledTableItem,
  StyledButtonWrapper,
  StyledInitButton,
  StyledSearchButtonWrapper,
  StyledTableItemWrapForInput,
  StyledSearchText,
} from './styles';
import Button from 'components/common/Button';
import useUser from 'hooks/useUser';
import { IUserInfo } from 'types/User.type';
import Pagination from 'components/Pagination';
import { MbtiResult } from 'constants/constants';

const User = () => {
  const {
    userList,
    getUserList,
    initPassword,
    pageIndex,
    allCount,
    setPageIndex,
    idValue,
    nameValue,
  } = useUser();

  useEffect(() => {
    getUserList();
  }, [pageIndex]);

  return (
    <StyledWrapper>
      <StyledTitle>계정 관리</StyledTitle>

      <StyledSearchButtonWrapper>
        <StyledSearchText>이름</StyledSearchText>
        <StyledTableItemWrapForInput
          onChange={(e) => {
            nameValue.current = e.target.value;
          }}
          placeholder={'이름을 입력해주세요'}
          maxLength={5}
        />
        <StyledSearchText>ID</StyledSearchText>
        <StyledTableItemWrapForInput
          onChange={(e) => {
            idValue.current = e.target.value;
          }}
          placeholder={'ID를 입력해주세요'}
          maxLength={20}
        />
        <Button
          content="검색"
          height="40%"
          width="5%"
          handleClick={getUserList}
        />
      </StyledSearchButtonWrapper>

      <StyledTableItemWrap>
        <table width="calc(100vw - 260px)">
          <TableHead
            titles={[
              { text: 'NO', value: 5 },
              { text: '이름', value: 10 },
              { text: 'ID', value: 10 },
              { text: '구독권리스트', value: 30 },
              { text: '와인취향', value: 5 },
              { text: '비밀번호초기화', value: 5 },
            ]}
          />
          <tbody>
            {userList.map((item: IUserInfo, index: number) => (
              <TableRow key={index}>
                <StyledTableItem>{index + 1}</StyledTableItem>
                <StyledTableItem>{item.userName}</StyledTableItem>
                <StyledTableItem>{item.email}</StyledTableItem>
                {/* <StyledTableItem>{item.subscriptionIdList}</StyledTableItem> */}
                <StyledTableItem>{item.subscriptionTypeList}</StyledTableItem>
                <StyledTableItem>
                  {
                    MbtiResult.find((e) => e.value === item.recommendationId)
                      ?.text
                  }
                </StyledTableItem>
                <StyledButtonWrapper>
                  <StyledInitButton
                    onClick={() => {
                      initPassword(item.email);
                    }}>
                    초기화
                  </StyledInitButton>
                </StyledButtonWrapper>
              </TableRow>
            ))}
          </tbody>
        </table>
      </StyledTableItemWrap>

      <Pagination
        allCount={allCount}
        selectedNumber={pageIndex}
        setSelectedNumber={setPageIndex}
        onClick={setPageIndex}
      />
    </StyledWrapper>
  );
};

export default memo(User);
