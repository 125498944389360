import customAxios from 'libs/CustomAxios';
import { IResponse } from 'types/Response.type';
import { IPopUp } from 'types/Common.type';

export const handleInsertPopup = async (req: IPopUp): Promise<IResponse> => {
  const url: string = `/popupImage`;
  const { data } = await customAxios.post(url, req);
  return data;
};

export const handleGetPopUpIamge = async (): Promise<IPopUp> => {
  const url: string = `/popupImage`;
  const { data } = await customAxios.get(url);
  return data.data;
};

export const handleSetEnablePopUPImage = async (req : string) : Promise<any> => {
  const url: string = `/popupImage/enable?enable=` + req;
  const {data} = await customAxios.get(url);
  return data.data;
}