import { ISelectBoxItem } from 'types/SelectBox.type';
import { IMbtiResult } from 'types/User.type';

export const MonthList: ISelectBoxItem[] = [
  { value: 5, name: '23년 5월' },
  { value: 6, name: '23년 6월' },
  { value: 7, name: '23년 7월' },
  { value: 8, name: '23년 8월' },
  { value: 9, name: '23년 9월' },
  { value: 10, name: '23년 10월' },
  { value: 11, name: '23년 11월' },
  { value: 12, name: '23년 12월' },
  { value: 1, name: '24년 1월' },
  { value: 2, name: '24년 2월' },
  { value: 3, name: '24년 3월' },
  { value: 16, name: '24년 4월' },
  { value: 17, name: '24년 5월' },
  { value: 18, name: '24년 6월' },
  { value: 19, name: '24년 7월' },
  { value: 20, name: '24년 8월' },
  { value: 21, name: '24년 9월' },
  { value: 22, name: '24년 10월' },
  { value: 23, name: '24년 11월' },
  { value: 24, name: '24년 12월' }
];

export const SubscriptionList: ISelectBoxItem[] = [
  { value: 1, name: '구독권A' },
  { value: 2, name: '구독권B' },
  { value: 3, name: '구독권C' },
  { value: 4, name: '구독권D' },
  { value: 5, name: '구독권E' },
];

export const FAQCategoryList: ISelectBoxItem[] = [
  { value: 1, name: '주문/결제' },
  { value: 2, name: '구독 와인' },
  { value: 3, name: '수령' },
  { value: 4, name: '중지/취소' },
];

export const MbtiResult: IMbtiResult[] = [
  {
    value: 1,
    type: 'A',
    text: '발전된 와린이형',
    subscription: '구독권A',
    description: '매월 50,000원 결제\n와인 두 병 제공',
  },
  {
    value: 2,
    type: 'B',
    text: '곧 죽어도 분위기형',
    subscription: '구독권B',
    description: '매월 50,000원 결제\n와인 한 병 제공',
  },
  {
    value: 3,
    type: 'C',
    text: '찐 애주가형',
    subscription: '구독권B',
    description: '매월 50,000원 결제\n와인 한 병 제공',
  },
  {
    value: 4,
    type: 'D',
    text: '전형적 와린이형',
    subscription: '구독권A',
    description: '매월 50,000원 결제\n와인 두 병 제공',
  },
  {
    value: 5,
    type: 'E',
    text: '반믈리에형',
    subscription: '구독권E',
    description: '매월 200,000원 결제\n와인 한 병 제공',
  },
  {
    value: 6,
    type: 'F',
    text: '고급 입맛형',
    subscription: '구독권C',
    description: '매월 100,000원 결제\n와인 두 병 제공',
  },
  {
    value: 7,
    type: 'G',
    text: '잠재적 와인 덕후형',
    subscription: '구독권D',
    description: '매월 100,000원 결제\n와인 한 병 제공',
  },
  {
    value: 8,
    type: 'H',
    text: '정체성을 찾고 있어형',
    subscription: '구독권D',
    description: '매월 100,000원 결제\n와인 한 병 제공',
  },
];
